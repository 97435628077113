import React, { Component } from "react";
import { Typography } from "@material-ui/core";

class About extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div>
          <Typography variant="h3">
            Dhrup’s custom carfted framework to solve business problems
            effectively.
          </Typography>
          <Typography variant="subtitle1">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. <br />
            Lorem Ipsum has been the industry's standard dummy
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
