import React from "react";
import { makeStyles } from "@material-ui/core";
export default function Consultation() {
  const useStyles = makeStyles(theme => ({
    toggle: {
      display: "block",
      cursor: "pointer",
      width: 35,
      height: 35,
      position: "relative",
      margin: " 0 auto",
      transition: "all 1s ease",
      "& div": {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        transition: "all 1s ease",
        "& span": {
          display: "block",
          position: "absolute",
          left: 0,
          right: 0,
          transform: "rotate(45deg)",
          top: "43%",
          transition: "all 1s ease",
          width: "100%",
          height: 2,
          background: "#0112c2",
          borderRadius: 30,
          margin: "0 auto"
        },
        "& span:first-child": {
          transform: "rotate(-45deg)"
        }
      },
      "& svg": {
        display: "block",
        fill: "none",
        stroke: "#0112c2",
        strokeWidth: 2,
        width: 44,
        height: 44,
        strokeLinecap: "round",
        position: "absolute",
        left: "50%",
        top: "50%",
        margin: "-24px 0 0 -22px",
        strokeDasharray: "0 74.801 8 82.801",
        strokeDashoffset: "82.801",
        transformOrigin: "50% 50%",
        backfaceVisibility: "hidden",
        transform: "scale(1)",
        transition: "all 1s ease"
      },
      "& svg:nth-child(3)": {
        transform: "rotate(180deg) scale(1)"
      },
      "&:hover": {
        "& div": {
          transform: "rotate(90deg) scale(0.7)",
          left: -1,
          top: -3
        },
        "& svg": {
          strokeDashoffset: 62,
          strokeDasharray: "0 82.801 62 82.801",
          transform: "rotate(90deg)",
          stroke: " #0112c2"
        },
        "& svg:nth-child(3)": {
          transform: "rotate(270deg)"
        }
      }
    },
    "@media(max-width:767px)": {
      toggle: {
        width: 20,
        height: 20
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.toggle}>
      <div>
        <div>
          <span></span>
          <span></span>
        </div>
        <svg>
          <use xlinkHref="#path" />
        </svg>
        <svg>
          <use xlinkHref="#path" />
        </svg>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 44 44"
          id="path"
        >
          <path d="M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22"></path>
        </symbol>
      </svg>
    </div>
  );
}
