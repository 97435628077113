import React from "react";
import "./App.css";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Routes from "./components/Routes";
import { Router } from "react-router-dom";
// import createHistory from "history/createBrowserHistory";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0112c2",
      dark: "#031b4e",
      light: "rgba(3, 27, 78, 0.9)",
      contrastText: "#fff"
    },
    darker: {
      main: "#212123"
    },
    secondary: {
      main: "#f7fafc"
    },
    default: {
      main: "#f12000"
    },
    text: {
      main: "#0112c2",
      primary: "#212123",
      secondary: "#111111"
    }
  },
  typography: {
    fontFamily: ["'Poppins'", "sans-serif"].join(","),
    subtitle1: {
      fontSize: 18,
      fontWeight: 500,
      color: "rgba(3, 27, 78, 0.8)",
      lineHeight: 1.6,
      "@media (max-width:1366px)": {
        fontSize: 16
      },
      "@media (max-width:959px)": {
        fontSize: 14
      },
      "@media (max-width:767px)": {
        fontSize: 12
      }
    },
    subtitle2: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.6,
      color: "#111111",
      "@media (max-width:1366px)": {
        fontSize: 16
      },
      "@media (max-width:767px)": {
        fontSize: 14
      }
    },
    h1: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.6,
      marginBottom: 25,
      color: "#031b4e",
      "@media (max-width:1366px)": {
        fontSize: 28,
        lineHeight: 1.3
      },
      "@media (max-width:1024px)": {
        fontSize: 26
      },
      "@media (max-width:959px)": {
        fontSize: 24
      },
      "@media (max-width:767px)": {
        fontSize: 18
      },
      "@media (max-width:599px)": {
        fontSize: 16
      }
    },
    h2: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.4,
      "@media (max-width:1366px)": {
        fontSize: 24
      },
      "@media (max-width:959px)": {
        fontSize: 20
      },
      "@media (max-width:767px)": {
        fontSize: 16
      }
    },
    h3: {
      fontSize: 24,
      fontWeight: 500,
      marginBottom: 25,
      lineHeight: 1.4,
      color: "#031b4e",
      "@media (max-width:1366px)": {
        fontSize: 22,
        marginBottom: 15
      },
      "@media (max-width:959px)": {
        fontSize: 18
      },
      "@media (max-width:767px)": {
        fontSize: 14
      }
    },
    h4: {
      fontSize: 14,
      marginBottom: 10,
      lineHeight: 1.6
    },
    body1: {
      fontWeight: 600,
      "@media (max-width:959px)": {
        fontSize: 14
      },
      "@media (max-width:767px)": {
        fontSize: 12
      }
    },
    body2: {
      fontSize: 18,
      fontWeight: 500,
      color: "#111111",
      lineHeight: 1.6,
      "@media (max-width:1366px)": {
        fontSize: 16
      },
      "@media (max-width:959px)": {
        fontSize: 14
      },
      "@media (max-width:767px)": {
        fontSize: 12
      }
    }
  },
  Container: {
    "@media (min-width:1280px)": {
      maxWidth: 1170
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Menu />
          <Routes />
          <Footer />
        </Router>
      </ThemeProvider>
    </div>
  );
}
export default App;
