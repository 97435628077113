import React from "react";
import { makeStyles } from "@material-ui/core";
export default function Radio(props) {
  const useStyles = makeStyles(theme => ({
    radio: {
      fontWeight: 500,
      "& input": {
        display: "none",
        "& + label": {
          paddingLeft: 40,
          position: "relative",
          cursor: "pointer",
          "&:before": {
            content: "''",
            position: "absolute",
            left: 0,
            width: 20,
            height: 20,
            border: "1px solid #dce0f6",
            borderRadius: "50%",
            top: 1
          }
        },
        "&:checked": {
          "& + label": {
            "&:before": {
              content: "''",
              borderColor: theme.palette.primary.main
            }
          }
        }
      }
    }
  }));

  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={"radio " + classes.radio}>
        <input
          type="radio"
          id={props.id}
          name={props.name}
          value={props.value}
        />
        <label htmlFor={props.id}>{props.label}</label>
      </div>
    </React.Fragment>
  );
}
