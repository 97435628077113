import React from "react";
import { makeStyles } from "@material-ui/core";
export default function Expert() {
  const useStyles = makeStyles(theme => ({
    telephone: {
      fontSize: theme.spacing(3),
      color: theme.palette.primary.main,
      fontWeight: "bold",
      lineHeight: "24px",
      marginLeft: 5,
      verticalAlign: "sub"
    }
  }));

  const classes = useStyles();
  return (
    <React.Fragment>
      <p>
        Discuss with expert,
        <br /> Give us ring at {"   "}
        <a href="tel:+1 6177 747 228" className={classes.telephone}>
          +1 6177 747 228
        </a>
      </p>
    </React.Fragment>
  );
}
