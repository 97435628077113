import React from "react";
//import { CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel";
import {
  CarouselProvider,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar
} from "@material-ui/core";
import photo1 from "../assets/img/photo1.png";
import photo2 from "../assets/img/photo2.png";
import photo3 from "../assets/img/photo3.png";
// import Slider1 from "react-animated-slider";

// import "react-animated-slider/build/horizontal.css";

export default function Testimonial() {
  const testi_words = [
    {
      id: 0,
      content:
        "“Excepteur sint occaecat cupidatat non proident, sunt in  cupa qui officia deserunt mollit anim id est laborum. Sed ut beatae nomnis iste natus.”",
      img: photo1,
      name: "Arthur Hansen",
      occupation: "CEO / Letters Inc"
    },
    {
      id: 1,
      content:
        "“Excepteur sint occaecat cupidatat non proident, sunt in  cupa qui officia deserunt mollit anim id est laborum. Sed ut beatae nomnis iste natus.”",
      img: photo2,
      name: "Annie Quinn",
      occupation: "Co-Founder / April Inc"
    },
    {
      id: 2,
      content:
        "“Excepteur sint occaecat cupidatat non proident, sunt in  cupa qui officia deserunt mollit anim id est laborum. Sed ut beatae nomnis iste natus.”",
      img: photo3,
      name: "Chester Torres",
      occupation: "VP of Product / Local Inc"
    }
  ];
  return (
    <div className="testimonial_slides">
      {/* <Slider1 autoplay={5000}>
        {testi_words.map((article, index) => (
          <div key={index}>
            <h4>{article.content}</h4>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={article.img} alt="" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={article.name}
                  secondary={article.occupation}
                />
              </ListItem>
            </List>
          </div>
        ))}
      </Slider1> */}

      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={3}
        dragEnabled={false}
      >
        <Slider>
          {testi_words.map((article, index) => (
            <Slide index={index} key={index}>
              <h4>{article.content}</h4>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={article.img} alt="" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={article.name}
                    secondary={article.occupation}
                  />
                </ListItem>
              </List>
            </Slide>
          ))}
        </Slider>
        <ButtonBack className="previousButton">
          <span className="arrow primera"></span>
          <span className="arrow segunda"></span>
          <div className="progress">
            <span className="progress-left">
              <span className="progress-bar"></span>
            </span>

            <span className="progress-right">
              <span className="progress-bar"></span>
            </span>
          </div>
        </ButtonBack>
        <ButtonNext className="nextButton">
          <span className="arrow primera"></span>
          <span className="arrow segunda"></span>
          <div className="progress">
            <span className="progress-left">
              <span className="progress-bar"></span>
            </span>

            <span className="progress-right">
              <span className="progress-bar"></span>
            </span>
          </div>
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
}
