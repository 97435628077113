import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useMediaQuery } from "react-responsive";
import {
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography
} from "@material-ui/core";
import photo1 from "../assets/img/blog.jpg";
import photo2 from "../assets/img/photo1.png";
import { Link } from "react-router-dom";
export default function Blog() {
  const blogs = [
    {
      id: 0,
      title: "Experience that help you to start the movement",
      blogby: "by Ronak Patel in ",
      content:
        "It is a long established fact that a reader will be distracted by the readable It is a long established fact that a reader...",
      img: photo1,
      date: "12",
      month: "08",
      year: "2019",
      profile: photo2,
      links: [
        { link_name: "Digital marketing,", link: "/" },
        { link_name: "SEO,", link: "/" },
        { link_name: "SMO", link: "/" }
      ]
    },
    {
      id: 1,
      title: "Experience that help you to start the movement",
      blogby: "by Ronak Patel in ",
      content:
        "It is a long established fact that a reader will be distracted by the readable It is a long established fact that a reader...",
      img: photo1,
      date: "12",
      month: "08",
      year: "2019",
      profile: photo2,
      links: [
        { link_name: "Digital marketing,", link: "/" },
        { link_name: "SEO,", link: "/" },
        { link_name: "SMO", link: "/" }
      ]
    },
    {
      id: 2,
      title: "Experience that help you to start the movement",
      blogby: "by Ronak Patel in ",
      content:
        "It is a long established fact that a reader will be distracted by the readable It is a long established fact that a reader...",
      img: photo1,
      date: "12",
      month: "08",
      year: "2019",
      profile: photo2,
      links: [
        { link_name: "Digital marketing,", link: "/" },
        { link_name: "SEO,", link: "/" },
        { link_name: "SMO", link: "/" }
      ]
    },
    {
      id: 3,
      title: "Experience that help you to start the movement",
      blogby: "by Ronak Patel in ",
      content:
        "It is a long established fact that a reader will be distracted by the readable It is a long established fact that a reader...",
      img: photo1,
      date: "12",
      month: "08",
      year: "2019",
      profile: photo2,
      links: [
        { link_name: "Digital marketing,", link: "/" },
        { link_name: "SEO,", link: "/" },
        { link_name: "SMO", link: "/" }
      ]
    },
    {
      id: 4,
      title: "Experience that help you to start the movement",
      blogby: "by Ronak Patel in ",
      content:
        "It is a long established fact that a reader will be distracted by the readable It is a long established fact that a reader...",
      img: photo1,
      date: "12",
      month: "08",
      year: "2019",
      profile: photo2,
      links: [
        { link_name: "Digital marketing,", link: "/" },
        { link_name: "SEO,", link: "/" },
        { link_name: "SMO", link: "/" }
      ]
    },
    {
      id: 5,
      title: "Experience that help you to start the movement",
      blogby: "by Ronak Patel in ",
      content:
        "It is a long established fact that a reader will be distracted by the readable It is a long established fact that a reader...",
      img: photo1,
      date: "12",
      month: "08",
      year: "2019",
      profile: photo2,
      links: [
        { link_name: "Digital marketing,", link: "/" },
        { link_name: "SEO,", link: "/" },
        { link_name: "SMO", link: "/" }
      ]
    }
  ];
  const useStyles = makeStyles(theme => ({
    blogSlide: {
      marginTop: 100,
      "& .MuiListItem-gutters": {
        padding: 0,
        alignItems: "flex-start",
        marginTop: 50,
        width: "80%",
        "& .MuiListItemAvatar-root": {
          minWidth: 86,
          "& .MuiAvatar-root": {
            width: 60,
            height: 60
          }
        },

        "& .MuiTypography-body1": {
          fontWeight: 400,
          "& .MuiTypography-h3": {
            fontSize: 22,
            marginBottom: 10
          },
          "& h6": {
            margin: 0,
            fontSize: 14,
            fontWeight: "normal",
            color: "rgba(3, 27, 78, 0.6)",
            display: "inline-block"
          }
        }
      }
    },
    links: {
      marginLeft: 5,
      "& .read_more ": {
        fontSize: 14,
        marginRight: 5
      }
    },
    blogdate: {
      background: theme.palette.secondary.main,
      marginLeft: 20,
      minWidth: 60,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-evenly",
      "& div:not(:first-child)": {
        borderTop: "1px solid #ebebeb",
        fontSize: 18
      },
      "& div": {
        width: "100%",
        height: "100%",
        fontWeight: 600,
        color: "#000000",
        fontSize: 40,
        position: "relative",
        "& span": {
          position: "absolute",
          left: 0,
          right: 0,
          top: "50%",
          transform: "translate(0, -50%)"
        }
      }
    },
    "@media (max-width:1440px)": {
      blogSlide: {
        marginTop: 50,
        "& .MuiListItem-gutters": {
          width: "100%",
          "& .MuiTypography-body1": {
            "& .MuiTypography-h3": {
              fontSize: 18
            }
          }
        }
      }
    },
    "@media (max-width:959px)": {
      blogdate: {
        flexDirection: "row",
        margin: 0,
        width: "100%",
        minHeight: 60,
        "& div:not(:first-child)": {
          borderTop: 0
        }
      }
    },
    "@media (max-width:767px)": {
      blogSlide: {
        marginTop: 30,
        "& .MuiListItem-gutters": {
          marginTop: theme.spacing(2),
          "& .MuiListItemAvatar-root": {
            minWidth: 50,
            "& .MuiAvatar-root": {
              width: 35,
              height: 35
            }
          },
          "& .MuiTypography-body1": {
            "& .MuiTypography-h3": {
              fontSize: 16
            }
          }
        }
      }
    }
  }));

  const classes = useStyles();
  const isBigger = useMediaQuery({
    query: "(min-device-width:600px)"
  });
  return (
    <div className={classes.blogSlide}>
      <Carousel
        centerMode
        centerSlidePercentage={isBigger ? 60 : 100}
        showStatus={false}
        showIndicators={false}
        infiniteLoop
        selectedItem={1}
        showThumbs={false}
        autoPlay={false}
        useKeyboardArrows
        transitionTime={1000}
      >
        {blogs.map(blog => (
          <div key={blog.id}>
            <Box display="flex" className="outerBlog">
              <img src={photo1} alt="..." />
              <Box className={classes.blogdate} display="flex">
                <div className={classes.date}>
                  <span>{blog.date}</span>{" "}
                </div>
                <div>
                  <span>{blog.month}</span>{" "}
                </div>
                <div>
                  <span>{blog.year}</span>
                </div>
              </Box>
            </Box>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <img src={blog.profile} alt="" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="h3">{blog.title}</Typography>
                  <h6>{blog.blogby} </h6>
                  <Box display="inline-block" className={classes.links}>
                    {blog.links.map((link, index) => (
                      <Link className="read_more " key={index} to={link.link}>
                        <span> {link.link_name}</span>
                      </Link>
                    ))}
                  </Box>
                  <p>{blog.content}</p>
                  <Link className="read_more" to="/">
                    <span>Read more >></span>
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
