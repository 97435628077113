import React from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
export default function Portfoliobox(props) {
  const useStyles = makeStyles(theme => ({
    portfolio: {
      "& .MuiPaper-elevation1": {
        boxShadow: "0 29px 60px 0 rgba(54, 57, 73, .09)",
        borderRadius: 0,
        "& .MuiCardContent-root": {
          padding: theme.spacing(2.4),
          "& p:not(.MuiTypography-body2)": {
            fontSize: 14
          }
        },
        "& img.MuiCardMedia-root": {
          height: 250
        }
      }
    },
    "@media (max-width:1279px)": {
      portfolio: {
        "& .MuiPaper-elevation1": {
          "& img.MuiCardMedia-root": {
            height: "auto"
          }
        }
      }
    },
    "@media (max-width:599px)": {
      portfolio: {
        width: "80%",
        margin: "0 auto 20px",
        "& .MuiPaper-elevation1": {
          boxShadow: "0 6px 20px 0 rgba(54, 57, 73, .09)"
        }
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.portfolio}>
      <Card>
        <CardActionArea component="div">
          <CardMedia
            component="img"
            alt="Website 1"
            image={props.imgsrc}
            title="Website 1"
          />
          <CardContent spacing={3}>
            <Typography
              gutterBottom
              variant="body2"
              className="fontWeightSemibold"
            >
              {props.title}
            </Typography>
            <p>{props.content}</p>
            <Link to="/" className="read_more">
              <span>Read More >></span>
            </Link>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}
