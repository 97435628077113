import React, { useEffect } from "react";
import logo from "../assets/img/logo.png";
import diamond from "../assets/img/diamond.png";
import techicon from "../assets/img/techicon.png";

// import $ from "jquery";
import {
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  TextField,
  RadioGroup,
  AppBar,
  Toolbar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Fade
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Expert from "../components/Expertadvice";
import Commitment from "../components/Commitment";
import Radio from "../components/Radio";
import Close from "../components/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Menu() {
  // scroll

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const [scroll, setScroll] = React.useState(false);

  function handleScroll() {
    if (document.documentElement.scrollTop !== 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }

  // sidebar

  //   const toggleDrawer = (side, open) => event => {
  //     setState({ ...state, [side]: open });
  //   };
  //   const [state, setState] = React.useState({
  //     left: false
  //   });

  //modal

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSubmit(false);
  };

  //submit

  const [submit, setSubmit] = React.useState(false);

  const handleSubmit = e => {
    setSubmit(true);
    e.preventDefault();
  };

  //const aboutmegamenuId = abouthover.isaboutHovering ? "comeup" : "";

  //language

  const [values, setValues] = React.useState({
    language: "EN",
    name: "language"
  });
  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));
  }

  //radio

  const [value, setValue] = React.useState("team");

  function handleRadio(event) {
    setValue(event.target.value);
  }

  //megamenu

  const [servicehover, setserviceHover] = React.useState({
    isserviceHovering: false
  });
  const [solutionhover, setsolutionHover] = React.useState({
    issolutionHovering: false
  });
  const [abouthover, setaboutHover] = React.useState({
    isaboutHovering: false
  });

  function handleserviceMouseHover() {
    setserviceHover(oldValues1 => ({
      ...oldValues1,
      isserviceHovering: true
    }));
    setsolutionHover(oldValues1 => ({
      ...oldValues1,
      issolutionHovering: false
    }));
    setaboutHover(oldValues1 => ({
      ...oldValues1,
      isaboutHovering: false
    }));
  }
  function handleserviceMouseOut() {
    setserviceHover(oldValues1 => ({
      ...oldValues1,
      // isserviceHovering: !servicehover.isserviceHovering
      isserviceHovering: false
    }));
  }
  function handlesolutionMouseHover() {
    setsolutionHover(oldValues1 => ({
      ...oldValues1,
      issolutionHovering: true
    }));
    setserviceHover(oldValues1 => ({
      ...oldValues1,
      isserviceHovering: false
    }));
    setaboutHover(oldValues1 => ({
      ...oldValues1,
      isaboutHovering: false
    }));
  }
  function handlesolutionMouseOut() {
    setsolutionHover(oldValues1 => ({
      ...oldValues1,
      issolutionHovering: false
    }));
  }
  function handleaboutMouseHover() {
    setaboutHover(oldValues1 => ({
      ...oldValues1,
      isaboutHovering: true
    }));
    setserviceHover(oldValues1 => ({
      ...oldValues1,
      isserviceHovering: false
    }));
    setsolutionHover(oldValues1 => ({
      ...oldValues1,
      issolutionHovering: false
    }));
  }
  function handleaboutMouseOut() {
    setaboutHover(oldValues1 => ({
      ...oldValues1,
      isaboutHovering: false
    }));
  }

  // Data

  const services = [
    {
      id: 0,
      title: "UI/UX Design",
      img: diamond,
      content: "There are many variations of passages of Lorem Ipsum available,"
    },
    {
      id: 1,
      title: "Website Design & Development",
      img: diamond,
      content: "There are many variations of passages of Lorem Ipsum available,"
    },
    {
      id: 2,
      title: "Mobile App Development",
      img: diamond,
      content: "There are many variations of passages of Lorem Ipsum available,"
    },
    {
      id: 3,
      title: "Digital Marketing(SEO, SEM, SMO)",
      img: diamond,
      content: "There are many variations of passages of Lorem Ipsum available,"
    }
  ];
  const technologies = [
    {
      id: 0,
      text: "UI & Frontend development",
      img: techicon
    },
    {
      id: 1,
      text: "Full stack development",
      img: techicon
    },
    {
      id: 2,
      text: "backend development",
      img: techicon
    },
    {
      id: 3,
      text: "Iphone App",
      img: techicon
    },
    {
      id: 4,
      text: "Iphone App",
      img: techicon
    },
    {
      id: 5,
      text: "Android App",
      img: techicon
    },
    {
      id: 6,
      text: "React Native & React JS",
      img: techicon
    },
    {
      id: 7,
      text: "Angular",
      img: techicon
    },
    {
      id: 8,
      text: "VUE js",
      img: techicon
    }
  ];

  // JSS

  const useStyles = makeStyles(theme => ({
    list: {
      width: "100%"
    },
    drawer: {
      backgroundColor: "transparent",
      borderBottom: "1px solid #ebebeb",
      height: theme.spacing(8),
      padding: theme.spacing(0, 7),
      boxShadow: "none",
      [theme.breakpoints.between("sm", "md")]: {
        padding: theme.spacing(0, 2)
      },
      [theme.breakpoints.only("xs")]: {
        height: theme.spacing(8),
        padding: theme.spacing(0, 2)
      }
    },
    toolBar: {
      padding: 0
    },
    toggleButton: {
      float: "right",
      marginLeft: theme.spacing(2)
    },
    logo: {
      display: "inline-block",
      padding: theme.spacing(2.5, 3, 1.5, 0),
      position: "relative",
      [theme.breakpoints.only("sm")]: {
        padding: theme.spacing(2.5, 2, 1.5, 0)
      },
      "&:after": {
        content: "''",
        position: "absolute",
        width: 1,
        height: 30,
        backgroundColor: "#ebebeb",
        right: 0,
        bottom: 1
      }
    },
    innerLogo: {
      display: "flex",
      height: 64,
      alignItems: "center",
      justifyContent: "center"
    },
    contact_number: {
      fontWeight: 600,
      letterSpacing: 1,
      padding: theme.spacing(2.4, 3, 2.5),
      position: "relative",
      display: "inline-block",
      color: theme.palette.primary.main,
      verticalAlign: "super",
      [theme.breakpoints.only("sm")]: {
        fontSize: theme.spacing(1.7),
        padding: theme.spacing(3, 2, 2.5)
      },
      "&:after": {
        content: "''",
        position: "absolute",
        width: 1,
        height: 30,
        backgroundColor: "#ebebeb",
        right: 0,
        bottom: 2
      }
    },
    nav_links: {
      display: "inline-block",
      float: "right"
    },
    inline: {
      display: "inline-block"
    },
    links: {
      padding: theme.spacing(3.8, 3, 2.5),
      verticalAlign: "bottom",
      height: 90,
      color: theme.palette.primary.light,
      letterSpacing: 0.5,
      display: "inline-block",
      "&>span": {
        transition: "all .3s ease-in",
        position: "relative",
        paddingRight: theme.spacing(2),
        "&:before": {
          content: "''",
          width: 8,
          height: 8,
          position: "absolute",
          top: 5,
          right: -3,
          zIndex: 9,
          borderLeft: "1px solid " + theme.palette.primary.light,
          borderBottom: "1px solid " + theme.palette.primary.light,
          transition: "all .5s ease",
          transform: "rotate(-45deg) scaleY(1)",
          transformOrigin: "center"
        }
      },
      "&:hover": {
        textDecoration: "none",
        color: theme.palette.primary.main,
        "&>span": {
          "&:before": {
            transform: "rotate(45deg) scaleY(-1)",
            top: 10
          }
        }
      }
    },
    get_in_touch: {
      height: 64,
      padding: theme.spacing(1, 3),
      fontSize: 12,
      textAlign: "center",
      position: "relative",
      color: theme.palette.primary.main,
      "& span": {
        paddingRight: 0,
        "&:after": {
          content: "none"
        },
        "&:before": {
          content: "none"
        }
      },
      "&:after": {
        content: "''",
        position: "absolute",
        width: 1,
        height: 30,
        backgroundColor: "#ebebeb",
        right: -1,
        bottom: 0
      }
    },
    get_in_touch_main: {
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
      display: "block",
      maxWidth: 104,
      margin: " 0 auto",
      borderBottom: "1px solid " + theme.palette.primary.main
    },
    grow: { flexGrow: 1 },
    lang_selection: {
      maxWidth: 70,
      width: "100%",
      position: "relative",
      "&:after": {
        content: "''",
        position: "absolute",
        width: 1,
        height: 30,
        backgroundColor: "#ebebeb",
        right: 0,
        bottom: 0
      }
    },

    first_link: {
      padding: theme.spacing(2.3, 0),
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        width: 1,
        height: 30,
        backgroundColor: "#ebebeb",
        left: 0,
        bottom: 13
      }
    },
    last_link: {
      position: "relative",
      "&:after": {
        content: "''",
        position: "absolute",
        width: 1,
        height: 30,
        backgroundColor: "#ebebeb",
        right: 0,
        bottom: 13
      }
    },
    toggleButtonInner: {
      width: 24,
      height: 2,
      background: "#454649",
      display: "block",
      position: "relative",
      marginTop: 14,
      transition: "all 0.5s ease-in-out",
      "&:before": {
        content: "''",
        position: "absolute",
        width: 18,
        height: 2,
        background: "#454649",
        display: "block",
        bottom: -6,
        right: 0,
        transition: "all 0.5s ease-in-out",
        border: 0,
        transform: "none"
      },
      "&:after": {
        content: "''",
        position: "absolute",
        width: 24,
        height: 2,
        background: "#454649",
        display: "block",
        top: -6,
        transition: "all 0.5s ease-in-out"
      }
    },
    megamenu: {
      maxWidth: 1230,
      position: "absolute",
      background: "#ffffff",
      boxShadow: "0 29px 60px 0 rgba(54, 57, 73, .09)",
      width: "100%",
      right: 70,
      top: 64,
      padding: "30px 20px",
      zIndex: 99,
      transform: "translateY(50px)"
    },
    smallmenu: {
      maxWidth: 470,
      padding: theme.spacing(0.5, 1, 1.2)
    },
    services: {
      "& .MuiListItem-gutters": {
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          background: theme.palette.secondary.main,
          "& .MuiTypography-body1": {
            color: theme.palette.primary.main
          }
        },
        "& .MuiTypography-body2": {
          fontSize: 14,
          color: theme.palette.primary.light,
          fontWeight: 400
        },
        "& .MuiTypography-body1": {
          color: theme.palette.primary.dark
        },
        "& .MuiListItemAvatar-root": {
          minWidth: 70,
          marginTop: 12,
          "& .MuiAvatar-root": {
            width: 50,
            height: "auto",
            "& .MuiAvatar-img": {
              width: "auto"
            }
          }
        }
      }
    },
    tech: {
      marginTop: 10,
      "& .MuiListItem-gutters": {
        padding: theme.spacing(0.4, 2),
        "&:hover": {
          background: "transparent"
        },
        "& .MuiTypography-body1": {
          color: theme.palette.primary.light,
          fontWeight: "normal"
        },
        "& .MuiListItemAvatar-root": {
          minWidth: 60,
          marginTop: 6
        }
      }
    },
    resources: {
      marginTop: 10,
      "& p": {
        color: theme.palette.primary.dark
      },
      "& h4": {
        color: theme.palette.primary.light,
        marginTop: 5,
        marginBottom: 20
      }
    },
    outsourcing: {
      padding: theme.spacing(3),
      background: theme.palette.primary.main,
      color: "#fff",
      marginTop: theme.spacing(6),
      "& .MuiTypography-body2": {
        color: "#fff"
      },
      "& h4": {
        color: "#fff",
        marginTop: 5,
        marginBottom: 20
      }
    },
    middlegrid: {
      position: "relative",
      "&:before": {
        content: "''",
        background: "#ebebeb",
        width: 1,
        height: "70%",
        position: "absolute",
        left: -30,
        top: "50%",
        transform: "translate(0,-50%)"
      },
      "&:after": {
        content: "''",
        background: "#ebebeb",
        width: 1,
        height: "70%",
        position: "absolute",
        right: -30,
        top: "50%",
        transform: "translate(0,-50%)"
      }
    },
    dialog: {
      fontWeight: 400,
      "& p": {
        maxWidth: 390
      },
      "& h5": {
        fontSize: 22,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 20
      },
      "& .MuiFormControl-root.textField": {
        maxWidth: 300,
        width: "100%"
      },
      "& .MuiFormGroup-root": {
        flexDirection: "row",
        marginBottom: 20,
        "& .radio": {
          marginRight: 50
        }
      },
      "& .space170": {
        marginTop: 170
      }
    },

    "@media (max-width:1366px)": {
      megamenu: { right: 0 },
      smallmenu: {
        maxWidth: 530
      },
      links: {
        padding: theme.spacing(3.8, 2, 2.5)
      },
      get_in_touch: {
        padding: theme.spacing(1, 2)
      }
    },
    "@media (max-width:1280px) and (min-width:1279px)": {
      first_link: {
        "&:before": {
          content: "none"
        }
      },
      contact_number: {
        padding: theme.spacing(2.4, 2.5, 2.5)
      }
    },
    "@media (max-width:1279px)": {
      dialog: {
        "& .MuiFormGroup-root": {
          "& .radio": {
            marginRight: 10
          }
        }
      },
      services: {
        "& .MuiListItem-gutters": {
          padding: theme.spacing(0.5),
          "& .MuiListItemAvatar-root": {
            minWidth: 50,
            "& .MuiAvatar-root": {
              width: 40
            }
          }
        }
      },
      outsourcing: {
        padding: theme.spacing(2)
      },
      tech: {
        "& .MuiListItem-gutters": {
          padding: theme.spacing(0),
          "& .MuiTypography-body1": {
            fontSize: 14
          },
          "& .MuiListItemAvatar-root": {
            minWidth: 50
          }
        }
      }
    },
    "@media (max-width:959px)": {
      dialog: {
        "& .space170": {
          marginTop: 30
        },
        "& .MuiTypography-h1": {
          fontSize: 22,
          "& br": {
            display: "none"
          }
        },
        "& p": {
          maxWidth: "100%"
        }
      },
      links: {
        height: 64,
        fontSize: theme.spacing(1.7),
        padding: theme.spacing(2.7, 2)
      },
      first_link: {
        "&:before": {
          bottom: 0
        }
      },
      last_link: {
        "&:after": {
          bottom: 0
        }
      },
      middlegrid: {
        "&:before": {
          left: -20
        },
        "&:after": {
          content: "none"
        }
      },
      megamenu: {
        "& .MuiGrid-justify-xs-space-between": {
          justifyContent: "space-around"
        }
      },
      smallmenu: {
        maxWidth: 250
      },
      outsourcing_col: {
        display: "flex",
        justifyContent: "space-around",
        marginTop: 20
      },
      outsourcing: {
        marginTop: 0,
        marginLeft: 15
      }
    },
    "@media(max-width:767px)": {
      dialog: {
        "& .MuiTypography-h1": {
          fontSize: 16
        },
        "& h5": {
          fontSize: 16
        },
        "& form": {
          "& .MuiFormControl-root.textField": {
            maxWidth: "100%"
          }
        }
      }
    }
  }));
  const classes = useStyles();

  return (
    <AppBar
      className={scroll ? classes.drawer + " sticky" : classes.drawer}
      square={true}
    >
      <Toolbar className={classes.toolBar}>
        <Hidden xsDown>
          <Link to="/" className={classes.logo}>
            <img src={logo} alt="Dhrup" />
          </Link>
          <Hidden only={["xs", "sm"]}>
            <a href="tel:+1 9898 980 980" className={classes.contact_number}>
              Call us: +1 9898 980 980
            </a>
          </Hidden>
          <div className={classes.grow} />
          <div className={classes.first_link + " " + classes.links}></div>
          <Link
            to="/Services"
            className={classes.links}
            onMouseEnter={() => handleserviceMouseHover()}
            onMouseLeave={() => handleserviceMouseOut()}
          >
            <span>Services</span>
          </Link>
          <Link
            to="/Solutions"
            className={classes.links}
            onMouseEnter={() => handlesolutionMouseHover()}
            onMouseLeave={() => handlesolutionMouseOut()}
          >
            <span> Solutions</span>
          </Link>
          <Link
            to="/About"
            className={classes.links}
            onMouseEnter={() => handleaboutMouseHover()}
            onMouseLeave={() => handleaboutMouseOut()}
          >
            <span> About</span>
          </Link>
          <Link
            to="/Contact"
            className={classes.last_link + " " + classes.links}
          >
            Contact
          </Link>
          <Hidden only={["xs", "sm", "md"]}>
            <Link
              to="/Contact"
              className={classes.links + " " + classes.get_in_touch}
            >
              <span className={classes.get_in_touch_main}>GET IN TOUCH</span>
              Response in 24 hours
            </Link>
            <FormControl className={classes.lang_selection}>
              <Select
                value={values.language}
                onChange={handleChange}
                name="language"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  className: "langmenu",
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value="EN">EN</MenuItem>
                <MenuItem value="HE">HE</MenuItem>
                <MenuItem value="FR">FR</MenuItem>
              </Select>
            </FormControl>
          </Hidden>
          {/* <div className={classes.toggleButton + " " + classes.links}>
            <span className={classes.toggleButtonInner}></span>
          </div> */}
          <div
            hidden={servicehover.isserviceHovering === false}
            className={
              servicehover.isserviceHovering
                ? "comeup " + classes.megamenu
                : classes.megamenu
            }
            onMouseEnter={() => handleserviceMouseHover()}
            onMouseLeave={() => handleserviceMouseOut()}
          >
            <Grid container justify="space-between">
              <Grid item md={4} sm={6}>
                <List className={classes.services}>
                  {services.map(service => (
                    <ListItem key={service.id} alignItems="flex-start">
                      <Box
                        component="a"
                        href="/"
                        className={classes.techblock}
                        display="flex"
                      >
                        <ListItemAvatar>
                          <Avatar alt="Travis Howard" src={service.img} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={service.title}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {service.content}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item className={classes.middlegrid}>
                <List className={classes.services + " " + classes.tech}>
                  {technologies.map(tech => (
                    <ListItem key={tech.id}>
                      <Box
                        component="a"
                        href="/"
                        className={classes.techblock}
                        display="flex"
                      >
                        <ListItemAvatar>
                          <Avatar src={tech.img} alt="icon"></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={tech.text} />
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item md={3} sm={12} className={classes.outsourcing_col}>
                <Box className={classes.resources}>
                  <Typography variant="body2" className="fontWeightSemibold">
                    Hire dedicated resource
                  </Typography>
                  <Typography variant="h4">
                    With flexible engagement models
                    <br /> and complete project evaluation
                  </Typography>
                  <Link to="/" className="read_more">
                    <span>Learn more >></span>
                  </Link>
                </Box>
                <Box className={classes.outsourcing}>
                  <Typography variant="body2" className="fontWeightSemibold">
                    IT Offshore outsourcing
                  </Typography>
                  <Typography variant="h4">
                    There are many variations of
                    <br />
                    passages of Lorem Ipsum available,
                  </Typography>
                  <Button
                    to="/"
                    variant="outlined"
                    className="btn btnWhite"
                    onClick={handleClickOpen}
                  >
                    Send us a message
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>

          <div
            hidden={solutionhover.issolutionHovering === false}
            className={
              solutionhover.issolutionHovering
                ? "comeup " + classes.megamenu
                : classes.megamenu
            }
            onMouseEnter={() => handlesolutionMouseHover()}
            onMouseLeave={() => handlesolutionMouseOut()}
          >
            <Grid container justify="space-between">
              <Grid item md={4} sm={6}>
                <List className={classes.services}>
                  {services.map(service => (
                    <ListItem key={service.id} alignItems="flex-start">
                      <Box
                        component="a"
                        href="/"
                        className={classes.techblock}
                        display="flex"
                      >
                        <ListItemAvatar>
                          <Avatar alt="Travis Howard" src={service.img} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={service.title}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {service.content}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item className={classes.middlegrid}>
                <List className={classes.services + " " + classes.tech}>
                  {technologies.map(tech => (
                    <ListItem key={tech.id}>
                      <Box
                        component="a"
                        href="/"
                        className={classes.techblock}
                        display="flex"
                      >
                        <ListItemAvatar>
                          <Avatar src={tech.img} alt="icon"></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={tech.text} />
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item md={3} sm={12} className={classes.outsourcing_col}>
                <Box className={classes.resources}>
                  <Typography variant="body2" className="fontWeightSemibold">
                    Hire dedicated resource
                  </Typography>
                  <Typography variant="h4">
                    With flexible engagement models
                    <br /> and complete project evaluation
                  </Typography>
                  <Link to="/" className="read_more">
                    <span>Learn more >></span>
                  </Link>
                </Box>
                <Box className={classes.outsourcing}>
                  <Typography variant="body2" className="fontWeightSemibold">
                    IT Offshore outsourcing
                  </Typography>
                  <Typography variant="h4">
                    There are many variations of
                    <br />
                    passages of Lorem Ipsum available,
                  </Typography>
                  <Button
                    to="/"
                    variant="outlined"
                    className="btn btnWhite"
                    onClick={handleClickOpen}
                  >
                    Send us a message
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div
            hidden={abouthover.isaboutHovering === false}
            className={
              abouthover.isaboutHovering
                ? "comeup " + classes.megamenu + " " + classes.smallmenu
                : classes.megamenu + " " + classes.smallmenu
            }
            onMouseEnter={() => handleaboutMouseHover()}
            onMouseLeave={() => handleaboutMouseOut()}
          >
            <List className={classes.services + " " + classes.tech}>
              {technologies.map(tech => (
                <ListItem key={tech.id}>
                  <Box
                    component="a"
                    href="/"
                    className={classes.techblock}
                    display="flex"
                  >
                    <ListItemText primary={tech.text} />
                  </Box>
                </ListItem>
              ))}
            </List>
          </div>

          {/* <IconButton
            onClick={toggleDrawer("left", true)}
            className={classes.toggleButton}
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            className="rightDrawer"
            transitionDuration={{
              enter: 1500,
              exit: 1000
            }}
            anchor="right"
            open={state.left}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            <div
              className={classes.list}
              role="presentation"
              onClick={toggleDrawer("left", false)}
              onKeyDown={toggleDrawer("left", false)}
            >
              <Grid container justify="space-between">
                <Grow
                  in={state.left}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(state.left ? { timeout: 5000 } : {})}
                >
                  <Grid item md={4} sm={6}>
                    Darshita
                  </Grid>
                </Grow>
                <Grow
                  in={state.left}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(state.left ? { timeout: 6000 } : {})}
                >
                  <Grid item className={classes.middlegrid}>
                    Darshita
                  </Grid>
                </Grow>
                <Grow
                  in={state.left}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(state.left ? { timeout: 7000 } : {})}
                >
                  <Grid item md={3} sm={12} className={classes.outsourcing_col}>
                    Darshita
                  </Grid>
                </Grow>
              </Grid>
            </div>
          </SwipeableDrawer> */}
        </Hidden>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          className={classes.dialog}
          TransitionComponent={Transition}
          transitionDuration={{
            enter: 800,
            exit: 500
          }}
        >
          <DialogContent>
            <div className="btnMadalClose" onClick={handleClose}>
              <Close />
            </div>
            <Grid container>
              <Grid item md={7} sm={12}>
                <Typography variant="h1">
                  Outsourcing partner
                  <br />& hire offshore developers.
                </Typography>
                <p>
                  We provide outsource team and also different specialists
                  separately, developer, designers, testers, UI/UX, Graphics or
                  another role, you can take the team or single specialist for
                  project or for hourly payed job.
                </p>
                <div className="space170"></div>
                <Expert />
              </Grid>
              <Grid item md={5} sm={12} className="position-relative">
                <Fade
                  in={!submit}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(!submit ? { timeout: 1000 } : {})}
                >
                  <div>
                    <h5>What do you need?</h5>
                    <form action="" noValidate autoComplete="off">
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <RadioGroup
                          aria-label="Help"
                          name="need"
                          value={value}
                          onChange={handleRadio}
                        >
                          <Radio
                            value="team"
                            name="help"
                            label="I need team"
                            id="team"
                          />
                          <Radio
                            value="specialist"
                            name="help"
                            label="I need specialist"
                            id="specialist"
                          />
                        </RadioGroup>
                      </FormControl>
                      <Grid container spacing={3}>
                        <Grid item sm={6} md={12} xs={12}>
                          <TextField
                            id="name"
                            label="Name"
                            className="textField"
                            variant="filled"
                          />
                        </Grid>

                        <Grid item sm={6} md={12} xs={12}>
                          <TextField
                            id="website"
                            label="Website"
                            className="textField"
                            variant="filled"
                          />
                        </Grid>

                        <Grid item sm={6} md={12} xs={12}>
                          <TextField
                            id="email"
                            type="email"
                            label="Email Address"
                            className="textField"
                            variant="filled"
                          />
                        </Grid>

                        <Grid item sm={6} md={12} xs={12}>
                          <TextField
                            id="phone"
                            label="Phone"
                            className="textField"
                            variant="filled"
                          />
                        </Grid>

                        <Grid item sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="sumbit"
                            className="btn"
                            onClick={handleSubmit}
                          >
                            Sent request
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                    <Commitment />
                  </div>
                </Fade>
                <Fade
                  in={submit}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(submit ? { timeout: 1000 } : {})}
                >
                  <div className={submit ? "go progress" : "progress"}>
                    <div className="loader">
                      <div className="dot dot-1"></div>
                      <div className="dot dot-2"></div>
                      <div className="dot dot-3"></div>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      className="checkmark"
                    >
                      <defs>
                        <filter id="goo">
                          <feGaussianBlur
                            in="SourceGraphic"
                            stdDeviation="10"
                            result="blur"
                          />
                          <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
                          />
                        </filter>
                      </defs>
                      <Fade
                        in={submit}
                        style={{ transformOrigin: "0 0 0" }}
                        {...(submit ? { timeout: 10000 } : {})}
                      >
                        <path
                          className={submit ? "checkmark__check" : ""}
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </Fade>
                    </svg>
                  </div>
                </Fade>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
}
