import React from "react";
import { makeStyles } from "@material-ui/core";

import { Link } from "react-router-dom";
export default function Commitment() {
  const useStyles = makeStyles(theme => ({
    commitment: {
      fontSize: 12,
      color: "#a4abc5",
      marginTop: 20
    }
  }));

  const classes = useStyles();
  return (
    <React.Fragment>
      <p className={classes.commitment}>
        We're committed to your privacy. For more information, <br /> check out
        our
        <Link to="/Privacy" className="read_more">
          <span> Privacy Policy.</span>
        </Link>
      </p>
    </React.Fragment>
  );
}
