import React from "react";
import {
  makeStyles,
  Typography,
  Container,
  Grid,
  Paper
} from "@material-ui/core";
import { Link } from "react-router-dom";
import breath_digital from "../assets/img/breath_digital.png";

export default function Breathdigitally() {
  const useStyles = makeStyles(theme => ({
    breathDigitally: {
      padding: theme.spacing(10, 0),
      background: theme.palette.primary.main,
      color: "#ffffff",
      "& .MuiGrid-grid-sm-6": {
        zIndex: 99
      }
    },
    textwhite: {
      color: "#ffffff"
    },
    breath: {
      width: 450,
      display: "block",
      background: theme.palette.primary.main,
      margin: "0 auto -80px",
      padding: "0 50px",
      maxWidth: "100%"
    },
    letsTalk: {
      backgroundColor: "transparent",
      padding: theme.spacing(7, 0),
      color: "#ffffff",
      border: "1px solid #ffffff",
      textAlign: "center",
      position: "relative",
      marginTop: theme.spacing(7),
      "&:after": {
        content: "''",
        position: "absolute",
        left: 50,
        top: -25,
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        width: 0,
        borderBottom: "25px solid #ffffff"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        left: 50,
        top: -23,
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        width: 0,
        borderBottom: "25px solid " + theme.palette.primary.main,
        zIndex: 9
      }
    },
    "@media (max-width:599px)": {
      breathDigitally: {
        padding: theme.spacing(5, 0),
        "& .MuiGrid-grid-sm-6": {
          width: " 100%"
        }
      },
      letsTalk: {
        padding: theme.spacing(5, 2),
        "&:after": {
          left: 15,
          top: -10,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderBottom: "10px solid #ffffff"
        },
        "&:before": {
          left: 15,
          top: -8,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderBottom: "10px solid " + theme.palette.primary.main
        },
        "& .MuiTypography-h2": {
          fontSize: 14
        }
      },
      breath: {
        width: 120,
        marginRight: 15,
        padding: "0 10px"
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.breathDigitally}>
      <Container>
        <Grid container>
          <Grid item sm={6}>
            <Typography variant="h4" className={classes.textwhite}>
              WE BREATH DIGITAL
            </Typography>
            <Typography variant="h3" className={classes.textwhite}>
              Good design is a renaissance attitude that combines technology,
              cognitive science, human need, and beauty to produce something
              that the world didn’t know it was missing.
            </Typography>
            <p>
              Do not address your readers as though they were gathered together
              in a stadium. When people read your copy, they are alone. Pretend
              you are writing to each of them a letter on behalf of your client.
            </p>
          </Grid>
          <Grid item sm={6}>
            <img src={breath_digital} alt="" className={classes.breath} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={12}>
            <Paper square={true} className={classes.letsTalk} elevation={0}>
              <Typography variant="h2">
                We ‘d love to help you create problem solving tools,{" "}
                <Link to="/" className="read_more_inverse">
                  <span>Let’s talk</span>
                </Link>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
