import React from "react";
import {
  makeStyles,
  Container,
  Grid,
  Paper,
  Box,
  Button
} from "@material-ui/core";
export default function Consultation(props) {
  const useStyles = makeStyles(theme => ({
    Consultation: {
      marginBottom: "-10%",
      textAlign: "center"
    },
    paper: {
      background: "#ffffff",
      boxShadow: "0 29px 60px 0 rgba(54, 57, 73, .09)",
      padding: theme.spacing(10, 0),
      borderRadius: 10
    },
    title: {
      fontSize: theme.spacing(5),
      fontWeight: 400,
      marginBottom: theme.spacing(5),
      marginTop: 0,
      lineHeight: 1,
      "& span": {
        fontWeight: 700,
        color: theme.palette.primary.main
      }
    },
    "@media (max-width:1366px)": {
      paper: {
        padding: theme.spacing(6, 2)
      },
      title: {
        fontSize: theme.spacing(4)
      }
    },
    "@media (max-width:1279px)": {
      title: {
        fontSize: theme.spacing(3)
      },
      Consultation: {
        marginBottom: "-15%"
      }
    },
    "@media (max-width:959px)": {
      Consultation: {
        marginBottom: "-20%"
      },
      title: {
        fontSize: theme.spacing(3)
      }
    },
    "@media (max-width:599px)": {
      Consultation: {
        marginBottom: "-40%"
      },
      title: {
        fontSize: theme.spacing(2.5),
        lineHeight: 1.2,
        marginBottom: theme.spacing(2.5)
      },
      paper: {
        padding: theme.spacing(2.5, 2.5, 4),
        "& .MuiBox-root": {
          marginTop: theme.spacing(2.5)
        }
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.Consultation}>
      <Container>
        <Grid container>
          <Grid item sm={12}>
            <Box textAlign="center">
              <Paper className={classes.paper}>
                <Box
                  component="h5"
                  className={classes.title}
                  dangerouslySetInnerHTML={{ __html: props.title }}
                ></Box>
                <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                <Box mt={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn"
                    onClick={props.handleClick}
                  >
                    {props.btnText}
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
