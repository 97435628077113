import React from "react";

import {
  makeStyles,
  Typography,
  Paper,
  InputBase,
  Button,
  Container,
  Grid
} from "@material-ui/core";

export default function Newsletter() {
  const useStyles = makeStyles(theme => ({
    newsBox: {
      padding: "60px 0 67px 70px",
      border: "1px solid",
      margin: "50px 0 150px",
      maxWidth: "93.5%"
    },
    paper: {
      background: "#ffffff",
      width: "100%",
      height: 175,
      display: "flex",
      position: "absolute",
      right: -80,
      top: -52,
      alignItems: "center"
    },
    positionRelative: {
      position: "relative"
    },
    banner_input: {
      height: 60,
      width: "calc(100% - 100px)",
      padding: theme.spacing(0, 2),
      border: "1px solid #dce0f6",
      borderRadius: "5px 0 0 5px",
      borderRight: 0,
      "& input": {
        fontSize: 14
      }
    },
    goButton: {
      width: 180,
      height: "60px !important",
      boxSizing: "border-box",
      borderRadius: "0 5px 5px 0!important"
    },
    "@media (max-width:1366px)": {
      banner_input: {
        width: "calc(100% - 80px)"
      },
      goButton: {
        maxWidth: 80
      }
    },
    "@media (max-width:1279px)": {
      newsBox: {
        padding: "40px 0 37px 40px",
        margin: "0 0 90px"
      },
      paper: {
        height: 120,
        top: -22
      }
    },
    "@media (max-width:959px)": {
      newsBox: {
        maxWidth: "100%",
        padding: theme.spacing(2, 2, 3.5),
        margin: "0 0 40px"
      },
      paper: {
        right: 0,
        top: "auto",
        bottom: -58,
        minWidth: 400,
        height: "auto"
      },
      goButton: {
        minWidth: "100px !important"
      }
    },
    "@media (max-width:599px)": {
      newsBox: {
        padding: theme.spacing(2)
      },
      paper: {
        position: "relative",
        top: "auto",
        minWidth: "100%",
        height: "auto",
        bottom: "auto"
      },
      banner_input: {
        height: 40
      },
      goButton: {
        height: "40px !important",
        fontSize: "14px !important"
      }
    }
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <div>
        <Container>
          <Grid container className={"newsletterBox " + classes.newsBox}>
            <Grid item md={6} sm={9} xs={12}>
              <Typography variant="body2">Newsletter</Typography>
              <p>
                Subscribe to get notified about new technology, web, digital
                <br />
                markeing insights, and ideas. You could unsubscribe at any time.
              </p>
            </Grid>
            <Grid
              item
              md={6}
              sm={3}
              className={classes.positionRelative}
              xs={12}
            >
              <Paper className={classes.paper} elevation={0}>
                <InputBase
                  className={classes.banner_input}
                  placeholder="email address"
                  type="email"
                />
                <Button
                  className={"btn btnBlack " + classes.goButton}
                  variant="contained"
                  type="submit"
                >
                  Sunscribe
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
