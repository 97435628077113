import React, { useEffect } from "react";
import {
  makeStyles,
  useTheme,
  Typography,
  Button,
  Container,
  Hidden,
  Grid,
  Tabs,
  Tab,
  Box,
  Dialog,
  DialogContent,
  TextField,
  Slide,
  Fade
} from "@material-ui/core";
import { Link } from "react-router-dom";
import $ from "jquery";
import Breathdigitally from "../components/Breathdigitally";
import Testimonial from "../components/Testimonial";
import Commitment from "../components/Commitment";
import Close from "../components/Close";
import Consultation from "../components/Consultation";
import SwipeableViews from "react-swipeable-views";
import Portfoliobox from "../components/Portfoliobox";
import Blog from "../components/Blog";
import banner from "../assets/img/banner.png";
import marketing from "../assets/img/digi_marketing.png";
import lock from "../assets/img/check.png";
import content from "../assets/img/creative_content.png";
import ui_ux from "../assets/img/ui_ux.png";
import mobile_app from "../assets/img/mobile_app.png";
import strategy from "../assets/img/strategy.png";
import portfolio from "../assets/img/portfolio.png";
import dothemath from "../assets/img/dothemath.jpg";
import FavoriteIcon from "@material-ui/icons/Favorite";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Home() {
  useEffect(() => {
    let i = 2;
    $(document).ready(function() {
      var radius = 50;
      var fields = $(".itemDot");
      var container = $(".dotCircle");
      var width = container.width();
      radius = width / 2.5;
      var height = container.height();
      var angle = 0,
        step = (2 * Math.PI) / fields.length;
      fields.each(function() {
        var x = Math.round(
          width / 2 + radius * Math.cos(angle) - $(this).width() / 2
        );
        var y = Math.round(
          height / 2 + radius * Math.sin(angle) - $(this).height() / 2
        );
        if (window.console) {
          console.log($(this).text(), x, y);
        }
        $(this).css({
          left: x + "px",
          top: y + "px"
        });
        angle += step;
      });
      var click = 1;
      $(".itemDot").click(function() {
        var dataTab = $(this).data("tab");
        $(".itemDot").removeClass("active");
        $(this).addClass("active");
        $(".CirItem").removeClass("active");
        $(".CirItem" + dataTab).addClass("active");
      });

      // setInterval(function() {
      //   $(".nextButton").trigger("click");
      // }, 10000);

      $(".nextButton").click(function() {
        $(".circular_slides")
          .addClass("forward")
          .removeClass("backward");
        if ($(".itemDot.last").hasClass("active")) {
          console.log("last Click");
          $(".itemDot.first").trigger("click");
        } else {
          $(".itemDot.active")
            .next()
            .trigger("click");
        }
        click++;
        i = click;
        $(".dotCircle").css({
          transform: "rotate(" + (360 - (i - 1) * 90) + "deg)",
          transition: "all 2s cubic-bezier(0.65, 0.97, 0, 1.6) 0.8s"
        });
      });
      $(".previousButton").click(function() {
        $(".circular_slides")
          .addClass("backward")
          .removeClass("forward");
        if ($(".itemDot.first").hasClass("active")) {
          $(".itemDot.last").trigger("click");
        } else {
          $(".itemDot.active")
            .prev()
            .trigger("click");
        }
        click--;
        i = click;
        $(".dotCircle").css({
          transform: "rotate(" + (360 - (i - 1) * 90) + "deg)",
          transition: "all 2s cubic-bezier(0.65, 0.97, 0, 1.6) 0.8s"
        });
      });
    });
  }, []);
  const framework = [
    {
      id: 1,
      title: "Dhrup’s<br/> captivating program",
      content:
        "It is a long established fact that a reader will be distracted by the readable",
      read_more: "Learn more >>"
    },
    {
      id: 2,
      title: "Enterprise<br/> unfaltering framework",
      content:
        "It is a long established fact that a reader will be distracted by the readable",
      read_more: "Learn more >>"
    },
    {
      id: 3,
      title: "Digital<br/> payback framework",
      content:
        "It is a long established fact that a reader will be distracted by the readable",
      read_more: "Learn more >>"
    }
  ];
  const grid = [
    {
      id: "grid1",
      sec_title: "Getting started with Dhrup",
      sec_subtitle:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      box: [
        {
          id: "box1",
          img: marketing,
          title: "Digital Marketing",
          content:
            "Dhrup is a full-service Internet marketing and SEO company offering innovative web marketing solutions",
          link: "/",
          class: "box1"
        },
        {
          id: "box2",
          img: content,
          title: "Creative content",
          content:
            "Dhrup is a full-service Internet marketing and SEO company offering innovative web marketing solutions",
          link: "/",
          class: "box2"
        },
        {
          id: "box3",
          img: ui_ux,
          title: "UI/UX design",
          content:
            "Dhrup is a full-service Internet marketing and SEO company offering innovative web marketing solutions",
          link: "/",
          class: "box3"
        },
        {
          id: "box4",
          img: content,
          title: "Development",
          content:
            "Dhrup is a full-service Internet marketing and SEO company offering innovative web marketing solutions",
          link: "/",
          class: "box4"
        },
        {
          id: "box5",
          img: mobile_app,
          title: "Mobile application",
          content:
            "Dhrup is a full-service Internet marketing and SEO company offering innovative web marketing solutions",
          link: "/",
          class: "xs_box5"
        },
        {
          id: "box6",
          img: strategy,
          title: "Strategy",
          content:
            "Dhrup is a full-service Internet marketing and SEO company offering innovative web marketing solutions",
          link: "/",
          class: "xs_box6"
        }
      ]
    },
    {
      id: "grid2",
      sec_title: "Getting started with Dhrup",
      sec_subtitle:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      box: [
        {
          id: "box5",
          img: mobile_app,
          title: "Mobile application",
          content:
            "Dhrup is a full-service Internet marketing and SEO company offering innovative web marketing solutions",
          link: "/",
          class: "box5"
        },
        {
          id: "box6",
          img: strategy,
          title: "Strategy",
          content:
            "Dhrup is a full-service Internet marketing and SEO company offering innovative web marketing solutions",
          link: "/",
          class: "box6"
        }
      ]
    }
  ];
  //modal
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSubmit(false);
  };
  //submit
  const [submit, setSubmit] = React.useState(false);
  const handleSubmit = e => {
    setSubmit(true);
    e.preventDefault();
  };
  //tab
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }
  const [value, setValue] = React.useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }
  function handleChangeIndex(index) {
    setValue(index);
  }
  const useStyles = makeStyles(theme => ({
    list: {
      width: 250
    },
    banner: {
      position: "relative",
      marginTop: -64,
      paddingTop: 240,
      textAlign: "center",
      backgroundColor: theme.palette.secondary.main
    },
    paper: {
      boxShadow: "4px 0 38px rgba(0,0,0,0.07)",
      maxWidth: 590,
      width: "100%",
      margin: "35px auto 0",
      height: 90,
      display: "flex"
    },
    banner_img: {
      maxWidth: "100%"
    },
    banner_input: {
      height: "100%",
      width: "calc(100% - 100px)",
      padding: theme.spacing(0, 2)
    },
    goButton: {
      maxWidth: 100,
      width: "100%",
      height: "100%",
      borderRadius: 0
    },
    // customFramework
    customFramework: {
      padding: theme.spacing(10, 0)
    },
    frameworkBox: {
      position: "relative"
    },
    frameworkImg: {
      padding: 30,
      position: "relative",
      minHeight: 349,
      backgroundColor: theme.palette.secondary.main,
      "&:after": {
        content: "''",
        position: "absolute",
        width: 1,
        height: "100%",
        backgroundColor: theme.palette.secondary.main,
        right: 0,
        top: 0,
        zIndex: 9
      }
    },
    frameworkCaption: {
      padding: 30,
      position: "relative"
    },
    //getStarted
    getStarted: {
      padding: theme.spacing(10, 0),
      "& .MuiGrid-grid-md-6:first-child": {
        "& .MuiBox-root": {
          display: "none"
        }
      }
    },
    techBox: {
      padding: theme.spacing(3.8),
      boxShadow: "0 29px 60px 0 rgba(54, 57, 73, .09)",
      borderRadius: 5,
      width: "43.423%",
      margin: "0 20px 40px",
      float: "left"
    },
    titleColor: {
      color: "#031b4e",
      fontWeight: 600
    },
    ml50: {
      marginLeft: 50
    },
    redirection: {},
    // workflow
    workflow: {
      padding: theme.spacing(10, 0)
    },
    // workflow
    workflow2: {
      padding: theme.spacing(10, 0),
      background: theme.palette.secondary.main,
      //   background:
      //     "linear-gradient(to right, #f7fafc 0%, #f7fafc 50%, #ffffff 50%, #ffffff 100%)",
      height: "100vh",
      overflow: "hidden"
    },
    howWork: {
      color: "#989898",
      fontWeight: 600
    },
    //testimonial
    testimonial: {
      padding: theme.spacing(10, 0),
      "& .innerBlock": {
        padding: theme.spacing(4, 0, 0, 8)
      }
    },
    blog: {
      padding: theme.spacing(10, 0),
      textAlign: "center"
    },
    portfolioBox: {
      padding: theme.spacing(7.25, 4)
    },
    dialog: {
      fontWeight: 400,
      "& p": {
        maxWidth: 390
      },
      "& h5": {
        fontSize: 22,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 20
      },
      "& .space170": {
        marginTop: 170
      },
      "& form": {
        "& .MuiFormControl-root.textField": {
          maxWidth: 300,
          width: "100%"
        },
        "& p": {
          fontSize: 14,
          maxWidth: 300,
          color: "#7286a2",
          margin: theme.spacing(0, 0, 3),
          position: "relative",
          paddingLeft: theme.spacing(4),
          "&:before": {
            content: "''",
            position: "absolute",
            width: 20,
            height: 20,
            left: 0,
            top: 5,
            background: "url(" + lock + ") no-repeat",
            backgroundSize: "contain"
          }
        }
      }
    },
    "@media (max-width:1366px)": {
      paper: {
        height: 70,
        maxWidth: 500
      },
      frameworkImg: {
        minHeight: 250
      },
      banner: {
        paddingTop: 120
      },
      portfolioBox: {
        padding: theme.spacing(8.1, 4)
      }
    },
    "@media (max-width:1280px)": {
      techBox: {
        width: "43.33%"
      }
    },
    "@media (max-width:1279px)": {
      techBox: {
        width: "45.836%",
        padding: 15,
        margin: theme.spacing(0, 1.25, 5)
      },
      portfolioBox: {
        padding: theme.spacing(3.8, 0, 3.8, 2)
      }
    },
    "@media (max-width:959px)": {
      dialog: {
        "& .MuiTypography-h1": {
          fontSize: 22,
          "& br": {
            display: "none"
          }
        },
        "& p": {
          maxWidth: "100%"
        }
      },
      customFramework: {
        padding: theme.spacing(5, 0)
      },
      frameworkImg: {
        padding: theme.spacing(2)
      },
      frameworkCaption: {
        padding: theme.spacing(0, 2)
      },
      workflow: {
        padding: theme.spacing(5, 0)
      },
      getStarted: {
        padding: theme.spacing(5, 0),
        "& .MuiGrid-grid-md-6:first-child": {
          "& .MuiBox-root": {
            display: "block",
            textAlign: "center"
          }
        },
        "& .MuiGrid-grid-md-6:last-child": {
          "& .MuiBox-root": {
            display: "none"
          }
        }
      },
      ml50: {
        marginLeft: 20,
        marginBottom: 20
      },
      techBox: {
        width: "47.22%",
        padding: 20,
        margin: theme.spacing(0, 1.25, 2.5)
      },
      testimonial: {
        "& .innerBlock": {
          padding: theme.spacing(3, 0, 0, 0),
          textAlign: "center"
        }
      },
      portfolioBox: {
        padding: theme.spacing(10, 0, 10, 4)
      }
    },
    "@media (max-width:767px)": {
      dialog: {
        "& .MuiTypography-h1": {
          fontSize: 16
        },
        "& h5": {
          fontSize: 16
        },
        "& form": {
          "& .MuiFormControl-root.textField": {
            maxWidth: "100%"
          }
        }
      },
      testimonial: {
        padding: theme.spacing(5, 0),
        "& .innerBlock": {
          padding: theme.spacing(0)
        }
      },
      blog: {
        padding: theme.spacing(5, 0)
      },
      frameworkCaption: {
        padding: theme.spacing(0, 1),
        whiteSpace: "normal"
      },
      banner: {
        padding: theme.spacing(15, 2, 0)
      },
      banner_img: {
        marginTop: theme.spacing(2)
      },
      techBox: {
        width: "46.379%"
      },
      portfolioBox: {
        padding: theme.spacing(5.25, 0, 5.25, 4)
      }
    },
    "@media (max-width:599px)": {
      banner: {
        "& br": {
          display: "none"
        }
      },
      testimonial: {
        "& br": {
          display: "none"
        },
        "& .MuiGrid-justify-xs-center": {
          display: "block",
          overflow: "scroll",
          whiteSpace: "nowrap",
          width: "100%",
          "& .MuiGrid-item": {
            width: "100%",
            display: "inline-block",
            "&  .MuiCardActionArea-root": {
              whiteSpace: "normal"
            }
          }
        }
      },
      portfolioBox: {
        padding: theme.spacing(0, 0, 2),
        textAlign: "center"
      },
      getStarted: {
        padding: theme.spacing(0),
        "& .MuiGrid-container:nth-child(2)": {
          display: "block",
          overflow: "scroll",
          whiteSpace: "nowrap",
          "& .MuiGrid-item": {
            width: "100%",
            display: "inline-block",
            overflow: "scroll",
            whiteSpace: "nowrap"
          },
          "& .MuiGrid-grid-md-6:last-child": {
            display: "none"
          },
          "& .MuiGrid-grid-md-6:first-child": {
            "& .MuiBox-root": {
              display: "none"
            }
          }
        }
      },
      techBox: {
        width: "80%",
        display: "inline-block",
        margin: "15px 10% 20%",
        float: "none",
        "& p": {
          whiteSpace: "normal"
        }
      },
      customFramework: {
        "& .MuiGrid-container": {
          display: "block",
          whiteSpace: "nowrap",
          overflow: "scroll"
        },
        "& .MuiGrid-grid-xs-12": {
          display: "inline-block",
          width: "100%"
        },
        "& br": {
          display: "none"
        }
      },
      workflow: {
        padding: theme.spacing(4, 0),
        "& br": {
          display: "none"
        },
        "& .MuiBox-root": {
          marginTop: 30
        }
      },
      frameworkBox: {
        width: "80%",
        margin: "0 auto"
      }
    }
  }));
  const classes = useStyles();
  const theme = useTheme();
  return (
    <React.Fragment>
      <div className={classes.banner}>
        <Typography variant="h1">
          We deploy custom tehcnologies and ensure that they're <br />
          customized to fit your specific needs.
        </Typography>
        <Typography variant="subtitle2">
          From identifying opportunities to execution and beyond, we work <br />
          in nimble, multidisciplinary teams that are thoughtfully
        </Typography>
        <img src={banner} className={classes.banner_img} alt="" />
      </div>

      {/* Framework */}
      <div className={classes.customFramework}>
        <Container>
          <Box textAlign="center">
            <Typography variant="h3">
              Dhrup’s custom carfted framework to solve business problems
              effectively.
            </Typography>
            <Typography variant="subtitle1">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. <br />
              Lorem Ipsum has been the industry's standard dummy
            </Typography>
          </Box>
          <Grid container justify="center" className="frameworkRow">
            {framework.map(value => (
              <Grid key={value.id} item sm={4} xs={12}>
                <div className={"firstFramework " + classes.frameworkBox}>
                  <div className="firstInnerbox">
                    <div className={classes.frameworkImg}>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: value.title }}
                      ></Typography>
                    </div>
                    <div className={classes.frameworkCaption}>
                      <p>{value.content}</p>
                      <Link to="/" className="read_more">
                        <span>{value.read_more}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <Hidden smUp>
            <p className="swipeText">Swipe left for more</p>
          </Hidden>
        </Container>
      </div>

      {/* getStarted */}
      <div className={"startwithus " + classes.getStarted}>
        <Container>
          <Hidden smUp>
            <Grid container>
              <Grid item>
                <Box textAlign="center">
                  <Typography variant="h3">
                    Getting started with Dhrup
                  </Typography>
                  <Typography variant="subtitle1">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Hidden>
          <Grid container justify="center">
            {grid.map(column => (
              <Grid key={column.id} item md={6}>
                <Box textAlign="left" className={classes.ml50}>
                  <Typography variant="h3">{column.sec_title}</Typography>
                  <Typography variant="subtitle1">
                    {column.sec_subtitle}
                  </Typography>
                </Box>
                {column.box.map(techbox => (
                  <div
                    key={techbox.id}
                    className={techbox.class + " " + classes.techBox}
                  >
                    <img src={techbox.img} alt="" className="img-fluid" />
                    <Typography className={classes.titleColor} variant="body2">
                      {techbox.title}
                    </Typography>
                    <p>{techbox.content}</p>
                    <Link
                      to={techbox.link}
                      className={classes.redirection}
                    ></Link>
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
          <Hidden smUp>
            <p className="swipeText">Swipe left for more</p>
          </Hidden>
        </Container>
      </div>

      {/* workflow */}
      <div className={classes.workflow}>
        <Container>
          <Box textAlign="center">
            <Typography variant="h3">
              Dhrup’s exceptional customer experiences freamwork
            </Typography>
            <Typography variant="subtitle1">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. <br />
              Lorem Ipsum has been the industry's standard dummy
            </Typography>
          </Box>
          <Grid container justify="center" className="frameworkRow">
            <Grid item sm={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  icon={
                    <span className="icons">
                      {" "}
                      <FavoriteIcon />
                    </span>
                  }
                  wrapped
                  label={
                    <div>
                      <span className="label"> Do the math</span>
                      <div className="progress">
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>

                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                      </div>
                    </div>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  icon={
                    <span className="icons">
                      {" "}
                      <FavoriteIcon />
                    </span>
                  }
                  wrapped
                  label={
                    <div>
                      <span className="label">
                        {" "}
                        Magnifying <br />
                        best equation
                      </span>
                      <div className="progress">
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>

                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                      </div>
                    </div>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  icon={
                    <span className="icons">
                      {" "}
                      <FavoriteIcon />
                    </span>
                  }
                  wrapped
                  label={
                    <div>
                      <span className="label">
                        {" "}
                        Implementation <br />
                        of magnified equation
                      </span>
                      <div className="progress">
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>

                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                      </div>
                    </div>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  icon={
                    <span className="icons">
                      {" "}
                      <FavoriteIcon />
                    </span>
                  }
                  wrapped
                  label={
                    <div>
                      <span className="label">
                        {" "}
                        Do the math = ROI
                        <br /> Repeat
                      </span>
                      <div className="progress">
                        <span className="progress-left">
                          <span className="progress-bar"></span>
                        </span>

                        <span className="progress-right">
                          <span className="progress-bar"></span>
                        </span>
                      </div>
                    </div>
                  }
                  {...a11yProps(3)}
                />
              </Tabs>
              <Box mt={10}>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography variant="h3">Do the math</Typography>
                        <Box mt={5} fontWeight="fontWeightRegular">
                          Dhrup is a full-service Internet marketing and SEO
                          company offering innovative web marketing solutions to
                          mid to large size companies across the globe. As a
                          leader in SEO, web design, ecommerce, website
                          conversion, and Internet marketing services.
                        </Box>
                        <Box mb={5} mt={4} fontWeight="fontWeightRegular">
                          We have an ever-expanding trophy cabinet for our web
                          design, SEO, and digital marketing work. Get in touch
                          with our team today to.
                        </Box>
                        <Link className="read_more" to="/">
                          <span> Read more &gt;&gt;</span>
                        </Link>
                      </Grid>
                      <Grid item sm={6}>
                        <img src={dothemath} alt="..." className="img-fluid" />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography variant="h3">
                          Magnifying best equation
                        </Typography>
                        <Box mt={5} fontWeight="fontWeightRegular">
                          Dhrup is a full-service Internet marketing and SEO
                          company offering innovative web marketing solutions to
                          mid to large size companies across the globe. As a
                          leader in SEO, web design, ecommerce, website
                          conversion, and Internet marketing services.
                        </Box>
                        <Box mb={5} mt={4} fontWeight="fontWeightRegular">
                          We have an ever-expanding trophy cabinet for our web
                          design, SEO, and digital marketing work. Get in touch
                          with our team today to.
                        </Box>
                        <Link className="read_more" to="/">
                          Rearn more &gt;&gt;
                        </Link>
                      </Grid>
                      <Grid item sm={6}>
                        <img src={dothemath} alt="..." className="img-fluid" />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography variant="h3">
                          Implementation of magnified equation
                        </Typography>
                        <Box mt={5} fontWeight="fontWeightRegular">
                          Dhrup is a full-service Internet marketing and SEO
                          company offering innovative web marketing solutions to
                          mid to large size companies across the globe. As a
                          leader in SEO, web design, ecommerce, website
                          conversion, and Internet marketing services.
                        </Box>
                        <Box mb={5} mt={4} fontWeight="fontWeightRegular">
                          We have an ever-expanding trophy cabinet for our web
                          design, SEO, and digital marketing work. Get in touch
                          with our team today to.
                        </Box>
                        <Link className="read_more" to="/">
                          Rearn more &gt;&gt;
                        </Link>
                      </Grid>
                      <Grid item sm={6}>
                        <img src={dothemath} alt="..." className="img-fluid" />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={3} dir={theme.direction}>
                    <Grid container>
                      <Grid item sm={6}>
                        <Typography variant="h3">
                          Do the math = ROI Repeat
                        </Typography>
                        <Box mt={5} fontWeight="fontWeightRegular">
                          Dhrup is a full-service Internet marketing and SEO
                          company offering innovative web marketing solutions to
                          mid to large size companies across the globe. As a
                          leader in SEO, web design, ecommerce, website
                          conversion, and Internet marketing services.
                        </Box>
                        <Box mb={5} mt={4} fontWeight="fontWeightRegular">
                          We have an ever-expanding trophy cabinet for our web
                          design, SEO, and digital marketing work. Get in touch
                          with our team today to.
                        </Box>
                        <Link className="read_more" to="/">
                          Rearn more &gt;&gt;
                        </Link>
                      </Grid>
                      <Grid item sm={6}>
                        <img src={dothemath} alt="..." className="img-fluid" />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </SwipeableViews>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.workflow2}>
        <div className="circular_slides carousel-slider">
          <Container>
            <Grid container>
              <Grid item sm={12}>
                <Typography variant="h4" className={classes.howWork}>
                  HOW WE WORK
                </Typography>
                <Typography variant="h3">
                  Dhrup’s exceptional customer experiences freamwork
                </Typography>
                <Typography variant="subtitle1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. <br />
                  Lorem Ipsum has been the industry's standard dummy
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <div className="holderCircle ">
            <button type="button" className="previousButton">
              <span className="arrow primera"></span>
              <span className="arrow segunda"></span>
              <div className="progress">
                <span className="progress-left">
                  <span className="progress-bar"></span>
                </span>

                <span className="progress-right">
                  <span className="progress-bar"></span>
                </span>
              </div>
            </button>
            <button type="button" className="nextButton">
              <span className="arrow primera"></span>
              <span className="arrow segunda"></span>
              <div className="progress">
                <span className="progress-left">
                  <span className="progress-bar"></span>
                </span>

                <span className="progress-right">
                  <span className="progress-bar"></span>
                </span>
              </div>
            </button>
            <div className="dotCircle">
              {/* <span className="highlightedDots"></span>
              <span className="highlightedDots"></span>
              <span className="highlightedDots"></span>
              <span className="highlightedDots"></span>
              <span className="highlightedDots"></span>
              <span className="highlightedDots"></span>
              <span className="highlightedDots"></span>
              <span className="highlightedDots"></span> */}

              <span className="itemDot itemDot1 first active" data-tab="1">
                <div className="img">
                  <img src={dothemath} alt="dothemath" />
                </div>
                <p>Magnifying best equation</p>
                <span className="number">1</span>
                <div className="progress">
                  <span className="progress-left">
                    <span className="progress-bar"></span>
                  </span>

                  <span className="progress-right">
                    <span className="progress-bar"></span>
                  </span>
                </div>
              </span>
              <span className="itemDot itemDot2" data-tab="2">
                <div className="img">
                  <img src={dothemath} alt="dothemath" />
                </div>
                <p>Magnifying best equation</p>
                <span className="number">2</span>
                <div className="progress">
                  <span className="progress-left">
                    <span className="progress-bar"></span>
                  </span>

                  <span className="progress-right">
                    <span className="progress-bar"></span>
                  </span>
                </div>
              </span>
              <span className="itemDot itemDot3" data-tab="3">
                <div className="img">
                  <img src={dothemath} alt="dothemath" />
                </div>
                <p>Magnifying best equation</p>

                <span className="number">3</span>
                <div className="progress">
                  <span className="progress-left">
                    <span className="progress-bar"></span>
                  </span>

                  <span className="progress-right">
                    <span className="progress-bar"></span>
                  </span>
                </div>
              </span>
              <span className="itemDot itemDot4 last" data-tab="4">
                <div className="img">
                  <img src={dothemath} alt="dothemath" />
                </div>
                <p>Magnifying best equation</p>
                <span className="number">4</span>
                <div className="progress">
                  <span className="progress-left">
                    <span className="progress-bar"></span>
                  </span>

                  <span className="progress-right">
                    <span className="progress-bar"></span>
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div className="contentCircle">
            <div className="CirItem CirItem1 active">
              <div className="content">
                <div>
                  <Typography variant="h3">Do the math</Typography>
                  <Box mb={5} mt={4} fontWeight="fontWeightRegular">
                    We have an ever-expanding trophy cabinet for our web design,
                    SEO, and digital marketing work. Get in touch with our team
                    today to.
                  </Box>
                  <Link className="read_more" to="/">
                    <span> Read more &gt;&gt;</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="CirItem CirItem2">
              <div className="content">
                <div>
                  <Typography variant="h3">Magnifying best equation</Typography>
                  <Box mb={5} mt={4} fontWeight="fontWeightRegular">
                    We have an ever-expanding trophy cabinet for our web design,
                    SEO, and digital marketing work. Get in touch with our team
                    today to.
                  </Box>
                  <Link className="read_more" to="/">
                    <span> Read more &gt;&gt;</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="CirItem CirItem3">
              <div className="content">
                <div>
                  <Typography variant="h3">
                    Implementation of magnified equation
                  </Typography>
                  <Box mb={5} mt={4} fontWeight="fontWeightRegular">
                    We have an ever-expanding trophy cabinet for our web design,
                    SEO, and digital marketing work. Get in touch with our team
                    today to.
                  </Box>
                  <Link className="read_more" to="/">
                    <span> Read more &gt;&gt;</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="CirItem CirItem4">
              <div className="content">
                <div>
                  <Typography variant="h3">
                    {" "}
                    Do the math = ROI Repeat
                  </Typography>
                  <Box mb={5} mt={4} fontWeight="fontWeightRegular">
                    We have an ever-expanding trophy cabinet for our web design,
                    SEO, and digital marketing work. Get in touch with our team
                    today to.
                  </Box>
                  <Link className="read_more" to="/">
                    <span> Read more &gt;&gt;</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Breathdigitally />
      {/* Testimonial */}

      <div className={classes.testimonial}>
        <Container>
          <Grid container spacing={5}>
            <Grid item md={6} sm={12}>
              <Box className="testimonialBox">
                <Typography variant="h3">
                  Exeperience that help you to start the movement
                </Typography>
                <Typography variant="subtitle1">
                  Get the exeperience over the things..
                </Typography>
              </Box>
              <div className="innerBlock">
                <Testimonial />
                <p className="fontWeightMedium">
                  Explore our{" "}
                  <Link to="/" className="read_more">
                    <span>Portfolio</span>
                  </Link>{" "}
                  &amp;{" "}
                  <Link to="/" className="read_more">
                    <span>Testimonials</span>
                  </Link>
                </p>
              </div>
            </Grid>
            <Grid item md={6} sm={12}>
              <Hidden xsUp>
                <Box className={classes.portfolioBox}>
                  <Typography variant="h3">
                    Project loved by our clients
                  </Typography>
                  <p className="fontWeightMedium">
                    Let's do this,{" "}
                    <Link className="read_more" to="/">
                      <span>Work with us</span>
                    </Link>
                  </p>
                </Box>
              </Hidden>
              <Grid container justify="center">
                <Grid item md={6} sm={5} xs={12}>
                  <Portfoliobox
                    imgsrc={portfolio}
                    title="Contrary to popular belief, Lorem ipsum is"
                    content="Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica"
                  />
                </Grid>
                <Grid item md={6} sm={5} xs={12}>
                  <Hidden xsDown>
                    <Box className={classes.portfolioBox}>
                      <Typography variant="h3">
                        Project loved by our clients
                      </Typography>
                      <p className="fontWeightMedium">
                        Let's do this,{" "}
                        <Link className="read_more" to="/">
                          <span>Work with us</span>
                        </Link>
                      </p>
                    </Box>
                  </Hidden>
                  <Portfoliobox
                    imgsrc={portfolio}
                    title="Contrary to popular belief, Lorem ipsum is"
                    content="Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Hidden smUp>
            <p className="swipeText">Swipe left for more</p>
          </Hidden>
        </Container>
      </div>
      {/* Blog */}
      <div className={classes.blog}>
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <Box>
                <Typography variant="h3">
                  Dhrup’s latest news and insights
                </Typography>
                <Typography variant="subtitle1">
                  Oubr blog, latest tech insights, read the tech stories
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Blog />
        <Hidden smUp>
          <p className="swipeText">Swipe left for more</p>
        </Hidden>
      </div>

      {/* <Marketingagency /> */}
      <Consultation
        title="Schedule a Free <span> 45 minute</span> consultation"
        content=" <a href='#'>34% </a>
                  of projects fail due to no baseline. Avoid project failure by
                  having a free consultation before doing anything else.
                  <br /> Drop us a note or give us a call <a href='tel:+1 6177.747.228'>+1 6177.747.228</a> we’re happy to answer all your questions."
        btnText="Grow your Business"
        handleClick={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        TransitionComponent={Transition}
        transitionDuration={{
          enter: 800,
          exit: 500
        }}
      >
        <DialogContent>
          <div className="btnMadalClose" onClick={handleClose}>
            <Close />
          </div>
          <Grid container>
            <Grid item md={7} sm={12}>
              <Typography variant="h1">
                Now is the tIme to
                <br /> measure the many
                <br /> dimensions
              </Typography>
              <p>
                We think it should be a wonderfully exciting moment, when you
                start questioning about your idea, you might discover other
                possibilities for growth and opportunities.
              </p>
            </Grid>
            <Grid item md={5} sm={12} className="position-relative">
              <Fade
                in={!submit}
                style={{ transformOrigin: "0 0 0" }}
                {...(!submit ? { timeout: 1000 } : {})}
              >
                <div>
                  <h5>Book free 45 minute consultation</h5>
                  <form action="" noValidate autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item md={12} sm={6} xs={12}>
                        <TextField
                          id="name"
                          label="Name"
                          className="textField"
                          variant="filled"
                        />
                      </Grid>

                      <Grid item md={12} sm={6} xs={12}>
                        <TextField
                          id="email"
                          type="email"
                          label="Your email address"
                          className="textField"
                          variant="filled"
                        />
                      </Grid>

                      <Grid item md={12} sm={6} xs={12}>
                        <TextField
                          id="phone"
                          label="Your phone number"
                          className="textField"
                          variant="filled"
                        />
                      </Grid>

                      <Grid item md={12} sm={6} xs={12}>
                        <TextField
                          id="message"
                          label="Message"
                          multiline
                          className="textField expand"
                          variant="filled"
                        />
                      </Grid>

                      <Grid item sm={12}>
                        <p className="nda">
                          Please send me a NDA (Non disclosure agreement) for a
                          consultation.
                        </p>
                        <Button
                          variant="contained"
                          color="primary"
                          type="sumbit"
                          className="btn"
                          onClick={handleSubmit}
                        >
                          Sent request
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Commitment />
                </div>
              </Fade>
              <Fade
                in={submit}
                style={{ transformOrigin: "0 0 0" }}
                {...(submit ? { timeout: 1000 } : {})}
              >
                <div>
                  <div className={submit ? "go progress" : "progress"}>
                    <div className="loader">
                      <div className="dot dot-1"></div>
                      <div className="dot dot-2"></div>
                      <div className="dot dot-3"></div>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      className="checkmark"
                    >
                      <defs>
                        <filter id="goo">
                          <feGaussianBlur
                            in="SourceGraphic"
                            stdDeviation="10"
                            result="blur"
                          />
                          <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
                          />
                        </filter>
                      </defs>
                      <Fade
                        in={submit}
                        style={{ transformOrigin: "0 0 0" }}
                        {...(submit ? { timeout: 10000 } : {})}
                      >
                        <path
                          className={submit ? "checkmark__check" : ""}
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </Fade>
                    </svg>
                  </div>
                </div>
              </Fade>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
