import React from "react";
import foot_logo from "../assets/img/foot_logo.png";
import { makeStyles, Container, Grid, Box, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Badge from "@material-ui/core/Badge";

export default function Footer() {
  const grid = [
    {
      id: "grid1",
      list: [
        {
          id: "list1",
          title: "Services",
          link: [
            { text: "UI/UX", to: "/", id: "link1" },
            { text: "Mobile Development", to: "/", id: "link2" },
            { text: "Digital Marketing", to: "/", id: "link3" },
            { text: "Web Designing", to: "/", id: "link4" },
            { text: "Web Development", to: "/", id: "link5" },
            { text: "Software Development", to: "/", id: "link6" }
          ]
        },
        {
          id: "list2",
          title: "For Business",
          link: [
            { text: "SEO Agency", to: "/", id: "link7" },
            { text: "PPC Agency", to: "/", id: "link8" },
            { text: "Web Design Agency", to: "/", id: "link9" },
            { text: "Web Development Agency", to: "/", id: "link10" },
            { text: "App Development Agency", to: "/", id: "link11" },
            { text: "Digital Marketing Agecny", to: "/", id: "link12" },
            { text: "Internet Marketing Agency", to: "/", id: "link13" },
            { text: "Socical Media Agency", to: "/", id: "link14" }
          ]
        }
      ]
    },
    {
      id: "grid2",
      list: [
        {
          id: "list3",
          title: "Industries",
          link: [
            { text: "Delivery & Transportation", to: "/", id: "link15" },
            { text: "Financial Services & Insurance", to: "/", id: "link16" },
            { text: "Media & Entertainment", to: "/", id: "link17" },
            { text: "Retail & eCommerce", to: "/", id: "link18" },
            { text: "Travel & Hospitality", to: "/", id: "link19" },
            { text: "Automotive & Manufacturing", to: "/", id: "link20" },
            { text: "Education & Libraries", to: "/", id: "link21" },
            { text: "Government", to: "/", id: "link22" },
            { text: "Healthcare", to: "/", id: "link23" },
            { text: "Manufacturing", to: "/", id: "link24" }
          ]
        }
      ]
    },
    {
      id: "grid3",
      list: [
        {
          id: "list4",
          title: "About",
          link: [
            { text: "About Dhrup", to: "/", id: "link25" },
            {
              text: "Careers",
              to: "/",
              badge: "We’re  hiring",
              class_name: "with_chip",
              id: "link26"
            },
            { text: "Testimonials", to: "/", id: "link27" },
            { text: "FAQ’s", to: "/", id: "link28" },
            { text: "Branding", to: "/", id: "link29" }
          ]
        },
        {
          id: "list5",
          title: "Resources",
          link: [
            { text: "Blog", to: "/", id: "link30" },
            { text: "Dhrup LABs", to: "/", id: "link31" },
            { text: "Products", to: "/", id: "link32" },
            { text: "Industies we serve", to: "/", id: "link33" },
            { text: "Partner with us", to: "/", id: "link34" }
          ]
        }
      ]
    }
  ];
  const socialIcon = [
    { icon: <FavoriteIcon />, link: "/", id: "icon1" },
    { icon: <FavoriteIcon />, link: "/", id: "icon2" },
    { icon: <FavoriteIcon />, link: "/", id: "icon3" },
    { icon: <FavoriteIcon />, link: "/", id: "icon4" },
    { icon: <FavoriteIcon />, link: "/", id: "icon5" }
  ];
  const useStyles = makeStyles(theme => ({
    footer: {
      padding: theme.spacing(35, 8, 4, 8),
      background: "#f7f8fd",
      lineHeight: 2
    },
    addLink: {
      display: "block",
      color: theme.palette.text.primary,
      fontSize: 14,
      "& svg": {
        color: theme.palette.text.primary,
        width: theme.spacing(2),
        height: theme.spacing(2),
        background: " #ffffff",
        borderRadius: "50%",
        fontSize: theme.spacing(2),
        boxShadow: "0 5px 7px rgba(0, 0, 0, 0.2)",
        marginLeft: 5,
        verticalAlign: "text-top"
      }
    },
    terms: {
      "& p": {
        margin: 0,
        fontSize: 12,
        textTransform: "uppercase",
        "& a": {
          padding: theme.spacing(0, 0.5),
          color: theme.palette.text.primary
        }
      }
    },
    socialMedia: {
      "& li": {
        verticalAlign: "middle",
        marginRight: theme.spacing(1),
        "& a": {
          display: "block",
          lineHeight: 1,
          color: theme.palette.text.primary,
          "& svg": {
            fontSize: 20
          }
        }
      }
    },
    title: {
      fontSize: theme.spacing(2),
      color: "#383838",
      fontWeight: 600,
      margin: 0,
      lineHeight: 1,
      "& a": {
        color: "#383838"
      }
    },
    pages_link: {
      "& a": {
        color: "rgba(3, 27, 78, 0.8)",
        fontSize: 14,
        fontWeight: 500,
        display: "inline-block",
        borderBottom: "1px solid transparent",
        lineHeight: 1.5,
        "&:hover": {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main
        }
      }
    },
    contact_detail: {
      fontSize: 14,
      marginBottom: 0,
      "& span": {
        width: 100,
        display: "inline-block"
      },
      "& a": {
        color: theme.palette.primary.main,
        fontWeight: 700
      }
    },
    "@media (max-width:1440px)": {
      contact_detail: {
        "& span": {
          width: 62
        }
      }
    },
    "@media (max-width:1366px)": {
      footer: {
        padding: theme.spacing(35, 5, 4, 5)
      }
    },
    "@media (max-width:1280px)": {
      footer: {
        padding: theme.spacing(35, 0, 4, 0)
      }
    },
    "@media (max-width:1279px)": {
      contact_detail: {
        "& span": {
          width: 50,
          fontSize: 12
        },
        "& a": {
          fontSize: 11
        }
      },
      footer: {
        padding: theme.spacing(28, 0, 4, 0)
      }
    },
    "@media (max-width:959px)": {
      footer: {
        "& .MuiGrid-justify-xs-space-evenly": {
          justifyContent: "space-between",
          margin: theme.spacing(0, 0, 6)
        }
      },
      contact_detail: {
        "& span": {
          width: 80,
          fontSize: 14
        },
        "& a": {
          fontSize: 14
        }
      }
    },
    "@media (max-width:767px)": {
      pages_link: {
        "& a": {
          fontSize: 11,
          lineHeight: 1
        }
      },
      addLink: {
        fontSize: 12
      },
      title: {
        fontSize: theme.spacing(1.8)
      },
      contact_detail: {
        "& span": {
          width: 60,
          fontSize: 12
        },
        "& a": {
          fontSize: 12
        }
      }
    },
    "@media (max-width:599px)": {
      footer: {
        "& .MuiGrid-grid-md-8 .MuiGrid-item:last-child": {
          width: "100%",
          "& div": {
            width: "50%",
            float: "left"
          }
        },
        "& .MuiGrid-justify-xs-space-evenly": {
          margin: theme.spacing(0, 0, 2)
        },
        "& .logo": {
          marginTop: 30
        }
      },
      xs50: {
        width: "50%",
        float: "left",
        "& div.MuiBox-root": {
          margin: theme.spacing(2, 0)
        }
      },
      contact_detail: {
        "& span": {
          width: 54
        }
      }
    },
    "@media (max-width:360px)": {
      contact_detail: {
        "& span": {
          width: 45,
          fontSize: 10
        },
        "& a": {
          fontSize: 10
        }
      },
      pages_link: {
        "& li": {
          lineHeight: 1.5
        },
        "& a": {
          fontSize: 9,
          lineHeight: 1
        }
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Container maxWidth="xl">
        <Grid container>
          <Hidden smDown>
            <Grid item md={2} sm={6}>
              <img src={foot_logo} className="imgResponsive" alt="" />
              <Box mt={3}>
                <Link className={classes.addLink} to="/">
                  Irvine, USA <RoomRoundedIcon />
                </Link>
                <Link className={classes.addLink} to="/">
                  Vadodara, India <RoomRoundedIcon />
                </Link>
              </Box>
              <ul className={"listInline " + classes.socialMedia}>
                {socialIcon.map(icons => (
                  <li key={icons.id}>
                    <Link to={icons.link}>{icons.icon}</Link>
                  </li>
                ))}
              </ul>
              <Box className={classes.terms}>
                <p> © Dhrup LLC, All rights reserved.</p>
                <p>
                  <Link to="/">PRIVACY POLICY</Link> /
                  <Link to="/">TERMS OF USE</Link> /<Link to="/">Sitemap</Link>
                </p>
              </Box>
            </Grid>
          </Hidden>
          <Grid item md={8} sm={12}>
            <Grid container justify="space-evenly">
              {grid.map(grids => (
                <Grid key={grids.id} item>
                  {grids.list.map(lists => (
                    <div key={lists.id}>
                      <h5 className={classes.title}>{lists.title}</h5>
                      <ul className={"listUnstyled " + classes.pages_link}>
                        {lists.link.map(links => (
                          <li key={links.id}>
                            <Link to={links.to}>
                              {links.text}{" "}
                              <Badge
                                color="primary"
                                badgeContent={links.badge}
                              ></Badge>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item md={2} sm={6} xs={12}>
            <div className={classes.xs50}>
              <h5 className={classes.title}>Call Us</h5>
              <Box component="p" className={classes.contact_detail}>
                <span>USA</span>{" "}
                <a href="tel:+ 1 6177 747 228">+ 1 6177 747 228</a>
              </Box>
              <Box component="p" mt={0} className={classes.contact_detail}>
                <span>India</span>{" "}
                <a href="tel:+ 91 9081 234 787">+ 91 9081 234 787</a>
              </Box>
              <Box mt={5}></Box>
            </div>
            <div className={classes.xs50}>
              <h5 className={classes.title}>write Us</h5>
              <Box component="p" className={classes.contact_detail}>
                <span>General</span>{" "}
                <a href="mailto:hello@dhrup.com">hello@dhrup.com</a>
              </Box>
              <Box component="p" mt={0} className={classes.contact_detail}>
                <span>Sales</span>{" "}
                <a href="mailto:biz@dhrup.com">biz@dhrup.com</a>
              </Box>
              <Box mt={5}></Box>
            </div>
            <div className={classes.xs50}>
              <h5 className={classes.title}>
                <Link to="/">Contact Us</Link>
              </h5>
            </div>
            <Box mt={2}></Box>

            <div className={classes.xs50}>
              <h5 className={classes.title}>
                <Link to="/">Client Login</Link>
              </h5>
            </div>
          </Grid>
          <Hidden mdUp>
            <Grid item md={2} sm={6} xs={12}>
              <img src={foot_logo} className="imgResponsive logo" alt="" />
              <Box mt={3}>
                <Link className={classes.addLink} to="/">
                  Irvine, USA <RoomRoundedIcon />
                </Link>
                <Link className={classes.addLink} to="/">
                  Vadodara, India <RoomRoundedIcon />
                </Link>
              </Box>
              <ul className={"listInline " + classes.socialMedia}>
                {socialIcon.map(icons => (
                  <li key={icons.id}>
                    <Link to={icons.link}>{icons.icon}</Link>
                  </li>
                ))}
              </ul>
              <Box className={classes.terms}>
                <p> © Dhrup LLC, All rights reserved.</p>
                <p>
                  <Link to="/">PRIVACY POLICY</Link> /
                  <Link to="/">TERMS OF USE</Link> /<Link to="/">Sitemap</Link>
                </p>
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
}
