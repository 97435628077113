import React from "react";
import {
  makeStyles,
  Typography,
  Container,
  Hidden,
  Button,
  TextField,
  Grid,
  Box,
  Breadcrumbs
} from "@material-ui/core";
import Consultation from "../components/Consultation";
import Mainservicelist from "../components/Mainservicelist";
import Expert from "../components/Expertadvice";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// import marketing from "../assets/img/digi_marketing.png";
// import content from "../assets/img/creative_content.png";
// import ui_ux from "../assets/img/ui_ux.png";
// import mobile_app from "../assets/img/mobile_app.png";
// import strategy from "../assets/img/strategy.png";

export default function Services() {
  const math = [
    {
      id: 1,
      link: "/",
      title: "Why you need local SEO ?"
    },
    {
      id: 2,
      link: "/",
      title: "Why local SEO is important ?"
    },
    {
      id: 3,
      link: "/",
      title: "What is local SEO services ?"
    },
    {
      id: 4,
      link: "/",
      title: "How to track local SEO ?"
    },
    {
      id: 5,
      link: "/",
      title: "Who is best local SEO company ?"
    },
    {
      id: 6,
      link: "/",
      title: " What are local SEO citations ?"
    }
  ];
  const services = [
    "Website design",
    "Website Development",
    "Mobile deelopment",
    "Startup solutions",
    "Enterprise solutions"
  ];
  const serviceList = [
    { id: 1, text: "Monthly SEO Services", link: "/" },
    { id: 2, text: "Expert SEO Consultation", link: "/" },
    { id: 3, text: "Optimizing On-site SEO", link: "/" },
    { id: 4, text: "Link Building", link: "/" },
    { id: 5, text: "Local & Map Optimization", link: "/" },
    { id: 6, text: "Pay Per Click Campaigns", link: "/" },
    { id: 7, text: "Powerful Social Signals", link: "/" },
    { id: 8, text: "Mobile SEO Services", link: "/" },
    { id: 9, text: "Video SEO Services", link: "/" },
    { id: 10, text: "Shopping SEO Services", link: "/" },
    { id: 11, text: " SEO Competition Analysis", link: "/" },
    { id: 12, text: "Keyword Density Analysis", link: "/" },
    { id: 13, text: "Amazon SEO", link: "/" },
    { id: 14, text: "Infographics", link: "/" },
    { id: 15, text: "Video creation", link: "/" }
  ];
  const useStyles = makeStyles(theme => ({
    serviceBanner: {
      background: theme.palette.secondary.main,
      padding: theme.spacing(10, 0, 7)
    },
    guidForm: {
      padding: theme.spacing(3),
      boxShadow: "0 29px 60px 0 rgba(54, 57, 73, .09)",
      borderRadius: 5,
      background: "#ffffff",
      maxWidth: 390
    },
    alternate: {
      color: "#b5bec8",
      textAlign: "center"
    },
    telephone: {
      fontSize: theme.spacing(3),
      color: theme.palette.primary.main,
      fontWeight: "bold",
      lineHeight: "24px",
      marginLeft: 5,
      verticalAlign: "sub"
    },
    doMath: {
      position: "relative",
      background: theme.palette.primary.main,
      width: 200,
      borderRadius: 5,
      height: 45,
      color: "#ffffff",
      fontWeight: 500,
      textAlign: "center",
      lineHeight: "45px",
      marginBottom: 40,
      marginTop: 20,
      "&:after": {
        content: "''",
        position: "absolute",
        background: theme.palette.primary.main,
        height: 25,
        width: 16,
        transform: "rotate(-65deg)",
        right: -15,
        top: 5
      },
      "&:before": {
        content: "''",
        position: "absolute",
        background: theme.palette.secondary.main,
        height: 36,
        width: 26,
        transform: "rotate(-65deg)",
        right: -30,
        top: 12,
        borderRadius: "50%",
        zIndex: 9
      }
    },
    pricingBox: {
      marginTop: 70,
      paddingLeft: 15
    },
    seolinks: {
      listStyle: "none",
      margin: 0,
      "& a": {
        color: theme.palette.primary.light,
        lineHeight: "35px",
        display: "block"
      }
    },
    services: {
      padding: theme.spacing(10, 0)
    },
    mt5: {
      marginTop: 50
    },
    mt3: {
      marginTop: 30
    },
    keypoints: {
      "& li": {
        color: theme.palette.primary.light,
        marginBottom: 30
      }
    },
    analysisBox: {
      maxWidth: 325,
      background: "#fff0ed",
      borderRadius: 5,
      padding: theme.spacing(5),
      margin: theme.spacing(5, 0, 0),
      "& h4": {
        fontSize: 72,
        color: "#ff6d4a",
        fontWeight: "500",
        margin: theme.spacing(0, 0, 3),
        lineHeight: "60px"
      },
      "& .MuiButtonBase-root": {
        margin: theme.spacing(4, 0, 0)
      }
    },
    serviceBox: {
      border: "1px solid #e9eff4",
      borderRadius: 5,
      marginTop: 40,
      padding: theme.spacing(3.5, 3),
      "& h6": {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.primary.main,
        margin: 0
      },
      "& ul": {
        listStyle: "none",
        padding: 0,
        margin: theme.spacing(2, 0, 0),
        fontWeight: 500,
        "& a": {
          color: "#212123"
        }
      }
    },
    pricing: {
      padding: theme.spacing(10, 0)
    },
    "@media (max-width:1366px)": {},
    "@media (max-width:1280px)": {},
    "@media (max-width:1279px)": {},
    "@media (max-width:959px)": {},
    "@media (max-width:767px)": {},
    "@media (max-width:599px)": {}
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* serviceBanner */}

      <div className={classes.serviceBanner}>
        <Container>
          <Grid container justify="space-between">
            <Grid item md={7} sm={7}>
              <Typography variant="h1">
                Best Local SEO Services <br />
                From Dhrup
              </Typography>
              <p>
                From identifying opportunities to execution and beyond, we work
                in nimble, multidisciplinary teams that are thoughtfully
                balanced according to the needs of each client and program. From
                identifying opportunities to execution and beyond, we work in
                nimble, multidisciplinary teams that are thoughtfully balanced
                according to the needs of each client and program.
              </p>
              <Box display="flex" className={classes.pricingBox}>
                <div>
                  <Box className={classes.doMath}>Do the math</Box>
                  <Hidden xsDown>
                    <Button
                      component={Link}
                      to="/"
                      variant="outlined"
                      color="primary"
                      className="btn w100"
                    >
                      View pricing
                    </Button>
                  </Hidden>
                </div>
                <ul className={classes.seolinks}>
                  {math.map(mathlink => (
                    <li key={mathlink.id}>
                      <Link to={mathlink.link}>{mathlink.title}</Link>
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
            <Grid item>
              <div className={classes.guidForm}>
                <form action="" noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        className="textField"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="email"
                        label="Email Address"
                        className="textField"
                        variant="filled"
                        type="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="Phone"
                        label="Phone"
                        className="textField"
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="btn w100"
                      >
                        Get the expert guide
                      </Button>
                    </Grid>
                  </Grid>
                  <p className={classes.alternate}>OR</p>
                  <Expert />
                </form>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* services */}

      <div className={classes.services}>
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <Breadcrumbs
                aria-label="breadcrumb"
                className={"float-right " + classes.mt5}
              >
                <Link to="/">Home</Link>
                <Link to="/">Pages</Link>
                <Typography color="textPrimary">Service</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item lg={3} md={4}>
              <ul className={"serviceList " + classes.mt5}>
                {serviceList.map(list => (
                  <Mainservicelist
                    key={list.id}
                    text={list.text}
                    link={list.link}
                  />
                ))}
              </ul>
              <div className={classes.serviceBox}>
                <h6>Other Services</h6>
                <ul>
                  {services.map(service => (
                    <li>
                      <Link>{service}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
            <Grid item lg={8} md={7}>
              <Box className={classes.mt5}>
                <Typography variant="h3">Local SEO</Typography>
                <Typography variant="subtitle1">
                  Pick your impetus below, and Let's start with most powerful
                  force available "Communication"
                </Typography>
              </Box>
              <Grid container justify="space-between" className={classes.mt3}>
                <Grid item lg={6} md={5}>
                  <p>
                    Dhrup is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen
                    book.
                  </p>
                  <ul className={classes.keypoints + " " + classes.mt5}>
                    <li>
                      Dhrup is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard.
                    </li>
                    <li>
                      Dhrup is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard.
                    </li>
                    <li>
                      Dhrup is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard.
                    </li>
                  </ul>
                </Grid>
                <Grid item>
                  <div className={classes.analysisBox}>
                    <h4>92%</h4>
                    <Typography variant="body2">
                      of searchers will pick businesses on the first page of
                      local search results.
                    </Typography>
                    <Button
                      to="/"
                      component={Link}
                      variant="outlined"
                      className="btn btnOrangeoutline"
                    >
                      Request a free analysis
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} className={classes.mt5}>
                  <Typography variant="body2">
                    Stay ahead of hackers. Sign up for Firefox Monitor and get
                    alerts?
                  </Typography>
                  <p className={classes.mt3}>
                    Dhrup is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an.
                  </p>
                  <p className={classes.mt3}>
                    Dhrup is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen
                    book. unknown printer took a galley of type and scrambled it
                    to make a type specimen book.
                  </p>
                  <p className={classes.mt3}>
                    Dhrup is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s,
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* pricing */}

      <div className={classes.pricing}>
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <Typography variant="h3">Local SEO pricing</Typography>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Consultation */}
      <Consultation
        title="We ensure the most delightful digital journey"
        content="let us test validation and scale growth channels for you so you can focus on other stuff."
        btnText="Enterprise"
      />
    </React.Fragment>
  );
}
