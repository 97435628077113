import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

export default function Mainservicelist(props) {
  const useStyles = makeStyles(theme => ({
    listItem: {
      listStyle: "none",
      "& a": {
        fontWeight: 500,
        color: theme.palette.primary.light,
        background: "#f2f8ff",
        lineHeight: "50px",
        display: "block",
        padding: theme.spacing(0, 3),
        borderBottom: "1px solid #e1e8fa",
        position: "relative",
        "&:before": {
          content: "'>>'",
          position: "absolute",
          right: 15,
          top: 2,
          color: theme.palette.primary.main,
          opacity: 0,
          transition: "all 0.3s linear"
        },
        "&:hover": {
          color: theme.palette.primary.main,
          "&:before": {
            right: 24,
            opacity: 1
          }
        }
      }
    }
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <li className={classes.listItem}>
        <Link to={props.link}>{props.text}</Link>
      </li>
    </React.Fragment>
  );
}
