import React from "react";
import { Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Home from "../pages/Home";
import Services from "../pages/Services";
import About from "../pages/About";
import Contact from "../pages/Contact";

const Routes = () => {
  return (
    <div className="main">
      <TransitionGroup>
        {/* no different than other usage of
                CSSTransition, just make sure to pass
                `location` to `Switch` so it can match
                the old location as it animates out
            */}
        <CSSTransition classNames="fade" timeout={300}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/About" component={About} />
            <Route path="/Services" component={Services} />
            <Route path="/Contact" component={Contact} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Routes;
